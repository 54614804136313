<template>
    <b-card>
        <b-row align-h="center">
            <b-col cols="8">
                <preview-image v-if="kitchenModel && kitchenMaterials" :circuits-hidden="circuitsHidden" :value="kitchenModel" :materials="kitchenMaterials" />
            </b-col>
            <b-col cols="4" v-if="kitchenData != null">
                <template v-for="(matGroup, index) in $options.KITCHEN_MATERIAL_GROUPS">
                    <b-card :title="matGroup.title" title-tag="h6" v-bind:key="index * 2 + 1" v-if="getMaterialData(matGroup) || matGroup.title">
                        {{ getMaterialData(matGroup) }}
                    </b-card>
                </template>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import api from '@/api/backend-api';
    import content_api from '@/api/backend-content';
    import PreviewImage from './preview/PreviewImage';

    const kitchenMaterialFields = {
        facadeFirstDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
        },
        facadeFirstMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeFirstSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeFirstPatina: {
            title:'Патина',
            materialType: 'PATINA',
            prefix: 'патина'
        },
        facadeSecondDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
        },
        facadeSecondMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeSecondSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeSecondPatina: {
            title:'Патина',
            materialType: 'PATINA',
            prefix: 'патина'
        },
        facadeAccentDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
        },
        facadeAccentMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeAccentSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeAccentPatina:  {
            title: 'Патина',
            materialType: 'PATINA',
            prefix: 'патина'
        },
        tableDecor: {
            title:'Столешница',
            materialType: 'TABLETOP_DECORATION',
            prefix: (material) => material?.vendor?.name
        },
        wallPanelDecor: {
            title:'Стеновая панель',
            materialType: 'WALL_DECORATION',
            prefix: (material) => material?.vendor?.name
        },
        cupboardHandle: {
            title:'Ручки',
            materialType: 'HANDLES',
        },
        metalProduct: {
            title:'Металлоизделия',
            materialType: 'METALWARE',
        },
    };
    
    export default {
        name: "KitchenPreview",
        components: {
            'preview-image': PreviewImage,
        },
        KITCHEN_MATERIAL_FIELDS: kitchenMaterialFields,
        KITCHEN_MATERIAL_GROUPS: [
            {
                title: "Фасад 1",
                materials: [ "facadeFirstMilling", "facadeFirstDecor", "facadeFirstPatina"]
            },
            {
                title: "Фасад 2",
                materials: ["facadeSecondMilling", "facadeSecondDecor", "facadeSecondPatina"],
                checkMaterial: 'facadeSecondDecor',
                alternativeMaterials: [ "facadeFirstMilling", "facadeFirstDecor", "facadeFirstPatina"]
            },
            {
                title: "Фасад-акцент",
                materials: [ "facadeAccentMilling","facadeAccentDecor","facadeAccentPatina"]
            },
            {
                title: "Столешница",
                materials: ["tableDecor"]
            },
            {
                title: "Панель",
                materials: ["wallPanelDecor"]
            }
        ],
        props: {
            id: {
                type: Number,
                required: true
            }
        },
        data(){
            return {
                kitchenData: null,
                kitchenModel: null
            }
        },
        computed: {
            circuitsHidden() {
                if (this.kitchenData == null) {
                    return false;
                }
                let hiddenCircField = this.kitchenData.facadeSecondDecor?.vendor?.hidePreviewCircuit;

                return hiddenCircField == null ? true : hiddenCircField;
            },
            kitchenMaterials() {
                if (this.kitchenData == null) {
                    return null;
                }
                let materials = Object.fromEntries(Object.keys(kitchenMaterialFields)
                                                         .map(materialField => {
                                                             let value = this.kitchenData[materialField];

                                                             if (!Array.isArray(value?.previewPath) || value.previewPath.length <= 0) {
                                                                 return null;
                                                             }
                                                             return [materialField, `/${content_api.imagesPath}/${value.previewPath[0]}`];
                                                         })
                                                         .filter(p => p!= null));

                if (!this.kitchenData.facadeSecondDecor) {
                    materials.facadeSecondDecor   = materials.facadeFirstDecor;
                    materials.facadeSecondSurface = materials.facadeFirstSurface;
                    materials.facadeSecondMilling = materials.facadeFirstMilling;
                    materials.facadeSecondPatina  = materials.facadeFirstPatina;
                }
                return materials;
            }
        },
        created() {
            api.getSingleKitchen(this.id)
               .then(resp => {
                   let fields = Object.keys(kitchenMaterialFields);
                   let kitchenDataTmp = Object.fromEntries( fields.map(f => [f,null]));
                   this.kitchenData = Object.assign({
                       id: null,
                       kitchenStyle: null,
                       kitchenSubStyle: null,
                       pricePerMeter: null,
                       tags: []
                   }, kitchenDataTmp, resp.data);
                   if (!this.kitchenData.facadeSecondDecor) {
                       this.kitchenData.facadeSecondDecor   = this.kitchenData.facadeFirstDecor;
                       this.kitchenData.facadeSecondSurface = this.kitchenData.facadeFirstSurface;
                       this.kitchenData.facadeSecondMilling = this.kitchenData.facadeFirstMilling;
                       this.kitchenData.facadeSecondPatina  = this.kitchenData.facadeFirstPatina;
                   }
                   return resp.data.kitchenStyle == null || resp.data.kitchenStyle.name == null
                        ? null : content_api.getModel(resp.data.kitchenStyle.name);
               })
               .then(resp => {
                   if (resp == null || resp.data == null) {
                       return;
                   }
                   if (resp.data.mainComp != null && resp.data.mainComp.image != null) {
                       resp.data.mainComp.image = `/${content_api.imagesPath}/${resp.data.mainComp.image}`;
                   }
                   if (Array.isArray(resp.data.circuits)) {
                       resp.data.circuits.forEach(c => {
                           if (c.image) {
                               c.image = `/${content_api.imagesPath}/${c.image}`;
                           }
                       });
                   }
                   this.kitchenModel = resp.data;
               });
        },
        methods: {
            
            getMaterialData(materialGroup) {
                let materials = !materialGroup.checkMaterial || this.kitchenData[materialGroup.checkMaterial]?.name
                              ? materialGroup.materials : materialGroup.alternativeMaterials;
                return materials.map(materialField => {
                    let materialObj = this.kitchenData[materialField];
                    let value = materialObj?.name;
                    if (!value) {
                        return null;
                    }
                    let config = kitchenMaterialFields[materialField];
                    if (config.prefix) {
                        if (typeof config.prefix === 'function') {
                            let prefix = config.prefix(materialObj);
                            if (prefix) {
                                value = prefix + ' ' + value;
                            }
                        }
                        else if (typeof config.prefix === 'string') {
                            value = config.prefix + ' ' + value;
                        }
                    }
                    return value;
                }).filter(name => !!name).join(', ');
            },
        }
    }
</script>

<style scoped>
    >>> .card .card-body {
        padding: 0.75rem;
    }

    >>> .card .card-body .card-title {
        font-weight: bold;
    }
</style>
