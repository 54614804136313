<template>
  <b-container>
    <EditUserComponent
        :login="login"/>
  </b-container>
</template>

<script>
import EditUserComponent from "@/components/EditUserComponent";

export default {
  name: "EditUserPage",
  props: {
    login: {
      type: String,
      required: true
    }
  },
  components: {EditUserComponent}
}
</script>

<style scoped>

</style>