<template>
    <div v-if="contentLoaded">
        <b-row>
            <b-form-group label="Стиль" class="col">
                <multiselect v-model="form.kitchenStyle" :options="styles" :multiple="true" :close-on-select="false"
                    :clear-on-select="false" :preserve-search="true" placeholder="Стиль" label="name" track-by="id"
                    select-label="Добавить"
                    deselect-label="Убрать"
                    selectedLabel="Выбрано">
                    <span slot="noResult">Не удалось найти стиль с данным именем</span>
                    <span slot="noOptions">Список пуст</span>
                </multiselect>
            </b-form-group>
            <b-form-group label="Подстиль" class="col">
                <multiselect v-model="form.kitchenSubStyle" :options="form.kitchenStyle" :multiple="true" group-values="subStyles"
                    group-label="name" :group-select="true" :close-on-select="false" placeholder="Подстиль" label="name" track-by="id"
                    select-label="Добавить"
                    deselect-label="Убрать"
                    selectedLabel="Выбрано"
                    :disabled="form.kitchenStyle.length === 0"
                    select-group-label="Выбрать все подстили"
                    deselect-group-label="Убрать все подстили"
                >
                    <span slot="noResult">Не удалось найти подстиль с данным именем</span>
                    <span slot="noOptions">Список пуст</span>
                </multiselect>
            </b-form-group>
            <b-form-group label="Доступность" class="col">
                <multiselect
                    label="name" track-by="value"
                    :options="availabilityOptions"
                    v-model="kitchenAvailable"
                    placeholder="Доступность"
                    :multiple="true"
                    select-label="Добавить"
                    deselect-label="Убрать"
                    selected-label="Выбрано"
                    :close-on-select="false"
                >
                    <span slot="noResult">Не найдено</span>
                    <span slot="noOptions">Список пуст</span>
                </multiselect>
            </b-form-group>
            <b-form-group class="col" label="Цена за погонный метр (в тыс.руб.)">
                <multiselect
                    v-model="form.pricePerMeter" :options="priceRanges"
                    :multiple="true"
                    :custom-label="({left, right}) => { return `${left} - ${right}`}"
                    :close-on-select="false"
                    track-by="left"
                    deselect-label="Убрать"
                    select-label="Добавить"
                    placeholder="Интервалы цен"
                    selected-label="Выбрано"
                    :taggable="true"
                    @tag="addPriceRange"
                    tag-placeholder="Добавить как интервал">
                    <span slot="noResult">Не найдено</span>
                    <span slot="noOptions">Список пуст</span>
                </multiselect>
            </b-form-group>
        </b-row>
        <b-row v-if="tagGroups">
            <b-form-group class="col" v-for="group in tagGroups" :key="group.name" :label="group.name">
                <multiselect v-model="form.tags[group.name]" :options="group.tagSubgroups"
                    :multiple="true" label="name"
                    track-by="id"
                    group-values="tags"
                    group-label="name"
                    :group-select="true"
                    :close-on-select="false"
                    deselect-label="Убрать"
                    select-label="Добавить"
                    placeholder="Теги"
                    selected-label="Выбрано">
                    <span slot="noResult">Не найдено</span>
                    <span slot="noOptions">Список пуст</span>
                </multiselect>
            </b-form-group>
        </b-row>
        <b-collapse :visible="showMaterialFilters && materialFiltersVisible">
            <b-card class="my-0" title="Фасад 1">
                <b-row>
                    <b-form-group label="Поставщик" class="col">
                        <multiselect v-model="form.facadeFirstDecor.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                            :clear-on-select="false" :preserve-search="true" placeholder="Поставщик"
                            label="name" track-by="id"
                            select-label="Добавить"
                            deselect-label="Убрать"
                            selectedLabel="Выбрано">
                            <span slot="noResult">Не найдено</span>
                            <span slot="noOptions">Список пуст</span>
                        </multiselect>
                    </b-form-group>
                    <b-form-group label="Фрезеровка" class="col">
                        <b-form-input v-model="form.facadeFirstMilling.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Декор" class="col">
                        <b-form-input v-model="form.facadeFirstDecor.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Поверхность" class="col">
                        <b-form-input v-model="form.facadeFirstSurface.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Патина" class="col">
                        <b-form-input v-model="form.facadeFirstPatina.name"></b-form-input>
                    </b-form-group>
                </b-row>
            </b-card>
            <b-card class="my-0" title="Фасад 2">
                <b-row>
                    <b-form-group label="Поставщик" class="col">
                        <multiselect v-model="form.facadeSecondDecor.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                            :clear-on-select="false" :preserve-search="true" placeholder="Поставщик"
                            label="name" track-by="id"
                            select-label="Добавить"
                            deselect-label="Убрать"
                            selected-label="Выбрано">
                            <span slot="noResult">Не найдено</span>
                            <span slot="noOptions">Список пуст</span>
                        </multiselect>
                    </b-form-group>
                    <b-form-group label="Фрезеровка" class="col">
                        <b-form-input v-model="form.facadeSecondMilling.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Декор" class="col">
                        <b-form-input v-model="form.facadeSecondDecor.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Поверхность" class="col">
                        <b-form-input v-model="form.facadeSecondSurface.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Патина" class="col">
                        <b-form-input v-model="form.facadeSecondPatina.name"></b-form-input>
                    </b-form-group>
                </b-row>
            </b-card>
            <b-card class="my-0" title="Фасад-Акцент">
                <b-row>
                    <b-form-group label="Поставщик" class="col">
                        <multiselect v-model="form.facadeAccentDecor.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                            :clear-on-select="false" :preserve-search="true" 
                            placeholder="Поставщик"
                            label="name" track-by="id"
                            select-label="Добавить"
                            deselect-label="Убрать"
                            selectedLabel="Выбрано">
                            <span slot="noResult">Не найдено</span>
                            <span slot="noOptions">Список пуст</span>
                        </multiselect>
                    </b-form-group>
                    <b-form-group label="Фрезеровка" class="col">
                        <b-form-input v-model="form.facadeAccentMilling.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Декор" class="col">
                        <b-form-input v-model="form.facadeAccentDecor.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Поверхность" class="col">
                        <b-form-input v-model="form.facadeAccentSurface.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Патина" class="col">
                        <b-form-input v-model="form.facadeAccentPatina.name"></b-form-input>
                    </b-form-group>
                </b-row>
            </b-card>
            <b-row no-gutters class="my-0">
                <b-col>
                    <b-card class="my-0" title="Столешница">
                        <b-form-group label="Поставщик">
                            <multiselect v-model="form.tableDecor.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :preserve-search="true"
                                placeholder="Поставщик"
                                label="name" track-by="id"
                                select-label="Добавить"
                                deselect-label="Убрать"
                                selectedLabel="Выбрано">
                                <span slot="noResult">Не найдено</span>
                                <span slot="noOptions">Список пуст</span>
                            </multiselect>
                        </b-form-group>
                        <b-form-group label="Декор">
                            <b-form-input v-model="form.tableDecor.name"></b-form-input>
                        </b-form-group>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card class="my-0" title="Панель">
                        <b-form-group label="Поставщик">
                            <multiselect v-model="form.wallPanelDecor.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :preserve-search="true" placeholder="Поставщик"
                                label="name" track-by="id"
                                select-label="Добавить"
                                deselect-label="Убрать"
                                selectedLabel="Выбрано">
                                <span slot="noResult">Не найдено</span>
                                <span slot="noOptions">Список пуст</span>
                            </multiselect>
                        </b-form-group>
                        <b-form-group label="Декор">
                            <b-form-input v-model="form.wallPanelDecor.name"></b-form-input>
                        </b-form-group>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card class="my-0" title="Ручки">
                        <b-form-group label="Поставщик">
                            <multiselect v-model="form.cupboardHandle.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :preserve-search="true" placeholder="Поставщик"
                                label="name" track-by="id"
                                select-label="Добавить"
                                deselect-label="Убрать"
                                selectedLabel="Выбрано">
                                <span slot="noResult">Не найдено</span>
                                <span slot="noOptions">Список пуст</span>
                            </multiselect>
                        </b-form-group>
                        <b-form-group label="Наименование">
                            <b-form-input v-model="form.cupboardHandle.name"></b-form-input>
                        </b-form-group>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card class="my-0" title="Металлоизделия">
                        <b-form-group label="Поставщик">
                            <multiselect v-model="form.metalProduct.vendor" :options="vendors" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :preserve-search="true" placeholder="Поставщик"
                                label="name" track-by="id"
                                select-label="Добавить"
                                deselect-label="Убрать"
                                selectedLabel="Выбрано">
                                <span slot="noResult">Не найдено</span>
                                <span slot="noOptions">Список пуст</span>
                            </multiselect>
                        </b-form-group>
                        <b-form-group label="Наименование">
                            <b-form-input v-model="form.metalProduct.name"></b-form-input>
                        </b-form-group>
                    </b-card>
                </b-col>
            </b-row>
        </b-collapse>
        <b-link class="mt-3" v-if="showMaterialFilters" href="#" @click="materialFiltersVisible = !materialFiltersVisible">
            <template v-if="materialFiltersVisible">Скрыть</template>
            <template v-else>Дополнительно</template>
        </b-link>
        <b-row class="my-3">
            <b-col cols="6">
                <b-form-input v-on:keyup.enter="applyFilter" placeholder="Имя материала" v-model="form.includedMaterialName"/>
            </b-col>
            <b-col class="ml-auto" cols="auto" v-if="kitchenCounter != null">
                <div class="available-options">Подходящих сочетаний: {{kitchenCounter}}</div>
            </b-col>
            <b-col cols="auto" :class="{'ml-auto': kitchenCounter == null}">
                <b-button v-on:keyup.enter="applyFilter" @click="applyFilter">Применить</b-button>
            </b-col>
            <b-col cols="auto">
                <b-button @click="clearForm" class="clear-btn">Очистить</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import api from '../api/backend-api';
    import Multiselect from 'vue-multiselect'
    import store from '../store/index'
    import {mapActions, mapMutations, mapState} from 'vuex'

    export default {
        name: "ToolbarFilters",
        store,
        props: {
            showMaterialFilters: {
                type: Boolean,
                default: true
            }
        },
        components: {
            Multiselect
        },
        data() {
            return {
                filterModified: false,
                materialFiltersVisible: false,
                kitchenCounter: null,
                form: {
                    kitchenStyle: [],
                    kitchenSubStyle: [],
                    availability: [],
                    pricePerMeter: [],
                    includedMaterialName: null,
                    facadeFirstDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeFirstMilling: {
                        name: null
                    },
                    facadeFirstSurface: {
                        name: null
                    },
                    facadeFirstPatina: {
                        name: null
                    },
                    facadeSecondDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeSecondMilling: {
                        name: null
                    },
                    facadeSecondSurface: {
                        name: null
                    },
                    facadeSecondPatina: {
                        name: null
                    },
                    facadeAccentDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeAccentMilling: {
                        name: null
                    },
                    facadeAccentSurface: {
                        name: null
                    },
                    facadeAccentPatina: {
                        name: null
                    },
                    tableDecor: {
                        name: null,
                        vendor: []
                    },
                    wallPanelDecor: {
                        name: null,
                        vendor: []
                    },
                    cupboardHandle: {
                        name: null,
                        vendor: []
                    },
                    metalProduct: {
                        name: null,
                        vendor: []
                    },
                    tags: {},
                }
            }
        },
        computed: {
            kitchenAvailable: {
                get() {
                    let value = this.form.availability;
                    if (value == null) {
                        return null;
                    }
                    else if (value === []) {
                        return [];
                    }
                    else {
                        let opts = this.availabilityOptions.filter(opt => this.form.availability.some(v => v === opt.value));
                        return opts;
                    }
                },
                set(opt) {
                    if (this.form == null) {
                        return;
                    }
                    
                    this.form.availability = opt?.map(o => o.value);
                }
            },
            availabilityOptions() {
                if (this.showMaterialFilters) {
                    return [
                        {name: 'Снят', value: 'UNAVAILABLE'},
                        {name: 'В СТОПе', value: 'STOP_SALES'},
                        {name: 'Доступно', value: 'AVAILABLE'},
                    ]
                }
                return [
                    {name: 'В СТОПе', value: 'STOP_SALES'},
                    {name: 'Доступно', value: 'AVAILABLE'},
                ]
            },
            tagGroups() {
                return this.tagGroupsData?.map(group => {
                    return {
                        name: group.name,
                        tagSubgroups: group.tagSubgroups
                                           .sort((tag1, tag2) => {
                                               if (tag1 == null || tag1.name == null || 'Общее' === tag1.name) {
                                                   return -1;
                                               }
                                               else if (tag2 == null || tag2.name == null || 'Общее' === tag2.name) {
                                                   return 1;
                                               }
                                               return tag1?.name?.localeCompare(tag2?.name, ["en", "ru"]);
                                           })
                                           .map(tsg => {
                                               return {
                                                   name: tsg.name,
                                                   tags: tsg.tags.sort((tag1, tag2) => tag1?.name?.localeCompare(tag2?.name, ["en", "ru"]))
                                               };
                                           })
                    };
                });
            },
            ...mapState({
                styles: 'styles',
                tagGroupsData: 'tagGroups',
                priceRanges: 'priceRanges',
                vendors: 'vendors',
                contentLoaded: state => state.stylesLoading.loaded && state.tagGroupsLoading.loaded && state.vendorsLoading.loaded && state.priceRangesLoading.loaded,
                userForm: state => state.user?.filter,
            })
        },
        watch: {
            "form.kitchenStyle": {
                handler: function (newVal, oldVal) {
                    if (newVal.length < oldVal.length) {
                        let subStylesArr = this.form.kitchenStyle.flatMap(function callback(element) {
                            return element.subStyles;
                        });
                        this.form.kitchenSubStyle = this.form.kitchenSubStyle.filter(element => subStylesArr.includes(element));
                    }
                }
            },
            form: {
                handler: function() {
                    this.filterModified = true;
                    api.getCounterData(this.processTags(this.form))
                       .then(resp => {
                           this.kitchenCounter = resp.data.count;
                           this.$emit('update-kitchen-counter', this.kitchenCounter);
                       })
                       .catch(() => {
                           this.kitchenCounter = null;
                       });
                },
                deep: true
            }
        },
        created() {
            this.loadVendors();
            this.loadStyles();
            this.loadTagGroups();
            this.loadPriceRanges();
            this.form = Object.assign(this.form, this.userForm);
            this.applyFilter(false);
        },
        methods: {
            clearForm() {
                this.form = {
                    kitchenStyle: [],
                    kitchenSubStyle: [],
                    availability: [],
                    pricePerMeter: [],
                    includedMaterialName: null,
                    facadeFirstDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeFirstMilling: {
                        name: null
                    },
                    facadeFirstSurface: {
                        name: null
                    },
                    facadeFirstPatina: {
                        name: null
                    },
                    facadeSecondDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeSecondMilling: {
                        name: null
                    },
                    facadeSecondSurface: {
                        name: null
                    },
                    facadeSecondPatina: {
                        name: null
                    },
                    facadeAccentDecor: {
                        name: null,
                        vendor: []
                    },
                    facadeAccentMilling: {
                        name: null
                    },
                    facadeAccentSurface: {
                        name: null
                    },
                    facadeAccentPatina: {
                        name: null
                    },
                    tableDecor: {
                        name: null,
                        vendor: []
                    },
                    wallPanelDecor: {
                        name: null,
                        vendor: []
                    },
                    cupboardHandle: {
                        name: null,
                        vendor: []
                    },
                    metalProduct: {
                        name: null,
                        vendor: []
                    },
                    tags: {}
                }
                this.applyFilter()
            },
            applyFilter(updateFilter = true) {
                if (updateFilter) {
                    this.updateUserFilter(this.form)
                    //api.updateUserFilter(this.form);
                }
                let filterChanged = this.filterModified;
                this.filterModified = false;
                this.$emit('apply-filter', this.processTags(this.form), updateFilter, filterChanged);
            },
            processTags(form) {
                return {
                    ...form,
                    tags: Object.keys(form.tags).map(key => form.tags[key]).flatMap(arr => arr)
                }
            },
            addPriceRange(tagValue) {
                let values = tagValue.split(/\s*-\s*/).map(s => Number(s.trim())).filter(n => !isNaN(n));
                if (values.length == 2 && values[0] <= values[1]) {
                    this.addPriceRangeToModel({left: values[0], right: values[1]});
                }
            },
            ...mapMutations({ addPriceRangeToModel :'ADD_NEW_PRICE_RANGE'}),
            ...mapActions(['loadTagGroups', 'loadStyles', 'loadVendors', 'updateUserFilter', 'loadPriceRanges'])
        }
    }
</script>

<style scoped>
    .available-options {
        padding: 0.375rem;
        line-height: 1.5;
    }
    .card {
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 0;
    }

    input {
        border-radius: 0;
    }

    .clear-btn {
        background-color: #6c757d !important;
        border-color: #6c757d !important;
    }

    .clear-btn:hover, .clear-btn:focus {
        /*background-color: #b81a01 !important;*/
        /*border-color: #b81a01 !important;*/
    }
</style>
<style>
    .b-sidebar {
        width: 400px !important;
    }
    .b-sidebar > .b-sidebar-body {
        background-color: #eef2f5 !important;
    }
    .b-sidebar > .b-sidebar-footer {
        background-color: #eef2f5 !important;
    }
    .multiselect__tags {
        border: 1px solid #ced4da !important;
        border-radius: 0 !important;
    }
</style>
