<template>
  <div>
    <b-container>
        <handles-list/>
    </b-container>
  </div>
</template>

<script>
    import KitchenHandlesList from '@/components/KitchenHandlesList.vue';

    export default {
        name: 'Home',
        components: {
            'handles-list': KitchenHandlesList
        },
        props: {
            /*page: {
               required: false,
               default: "1",
               validator: (prop) => typeof prop === 'string' || typeof prop === 'number'
               }*/
        }
    }
</script>
<style scoped>
</style>
