<template>
    <b-row>
        <b-col>
            <material-card
                title="Старый материал"
                :state="oldMaterialState"
                :material="oldMaterial"
                @replace-material="(ev) => $emit('replace-old-material', ev)"
                @drop-material="(ev) => $emit('drop-old-material', ev)"
            >
            </material-card>
        </b-col>
        
        <b-col>
            <material-card
                title="Декор"
                :state="decorMaterialState"
                :material="decorMaterial"
                @replace-material="(ev) => $emit('replace-decor-material', ev)"
                @drop-material="(ev) => $emit('drop-decor-material', ev)"
            >
            </material-card>
            <material-card
                title="Фрезеровка"
                :state="millingMaterialState"
                :material="millingMaterial"
                @replace-material="(ev) => $emit('replace-milling-material', ev)"
                @drop-material="(ev) => $emit('drop-milling-material', ev)"
            >
            </material-card>
        </b-col>
        <b-col>
            <material-card
                title="Поверхность"
                :state="surfaceMaterialState"
                :material="surfaceMaterial"
                @replace-material="(ev) => $emit('replace-surface-material', ev)"
                @drop-material="(ev) => $emit('drop-surface-material', ev)"
            >
            </material-card>
            <material-card
                title="Патина"
                :state="patinaMaterialState"
                :material="patinaMaterial"
                @replace-material="(ev) => $emit('replace-patina-material', ev)"
                @drop-material="(ev) => $emit('drop-patina-material', ev)"
            >
            </material-card>
        </b-col>
    </b-row>
</template>

<script>
    import MaterialCard from './MaterialCard';
    export default {
        components: {
            'material-card': MaterialCard
        },
        props: {
            oldMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            decorMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            millingMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            surfaceMaterial : {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            patinaMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            oldMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            decorMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            millingMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            surfaceMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            patinaMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            }
        },
    }
</script>
