<template>
    <b-form @submit.prevent="onSubmit" method="post">
        <b-form-input v-model="login" placeholder="Логин" name="login" type="text" class="mt-3" />
        <b-form-input v-model="password" name="password" placeholder="Пароль" class="mt-3" type="password" />
        <b-alert class="mt-2" variant="danger" :show="errorMessage != null" dismissible @dismissed="errorMessage=null">
            {{ errorMessage }}
        </b-alert>
        <b-button type="submit" class="form-control mt-3">Вход</b-button>
    </b-form>
</template>
<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'Login',
        data(){
            return {
                login: '',
                password: '',
                errorMessage: null,
                submitting: false
            };
        },
        methods: {
            onSubmit(el) {
                if (this.submitting) {
                    return;
                }
                this.submitting = true;
                let formData = new FormData(el.target);
                let login = formData.get('login');
                let password = formData.get('password');
                if (login == null || login === "" || password == null || password === "") {
                    return;
                }
                this.errorMessage = null;
                this.performLogin({login, password})
                    .then(() => {
                        this.$router.push('/');
                    })
                    .catch(err => {
                        if (err.response.status == 403) {
                            this.errorMessage = "Логин или пароль указаны неверно";
                        }
                    })
                    .finally(() => {
                        this.submitting = false;
                    });
            },
            ...mapActions(['performLogin'])
        }
    }
</script>
