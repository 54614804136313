<template>
  <b-container>
    <ShowUserComponent/>
  </b-container>
</template>

<script>
import ShowUserComponent from "@/components/ShowUserComponent";

export default {
  name: "ShowUserPage",
  components: {ShowUserComponent},
}
</script>

<style scoped>

</style>