<template>
    <b-container>
        <b-row align-h="center">
            <b-col cols="10" md="8" lg="6" xl="6">
                <b-card class="d-flex justify-content-center mt-4" title="Вход" align="center">
                    <login/>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import LoginComponent from '../components/LoginComponent';
    export default {
        name: 'Login',
        components: {
            'login':LoginComponent
        }
    }
</script>
