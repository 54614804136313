import {AXIOS} from "./backend-config";
import store from '@/store';
export default {
    login(login, password){
	return AXIOS.post('/login', { login, password});
    },
    logout(){
	return AXIOS.post('/logout')
                    .then(resp => {
                        store.state.user = null;//сброс информации о пользователе
                        return resp;
                    });
    },
    getCurrentUserInfo(){
        return AXIOS.get('/user/current');
    }
}
