<template>
    <div class="row-details-container">
        <div class="position-relative row-detail-preview">
            <router-link class="d-block" :to="{path: `/${row.id}/preview` }" target="_blank">
                <preview-image v-if="kitchenModel && kitchenMaterials" :circuits-hidden="circuitsHidden" :value="kitchenModel" :materials="kitchenMaterials" />
                <img v-else class="preview-image" height="150" src="https://img.mebelved.ru/thumb/6e/88908-0.jpg">
            </router-link>
        </div>
        <decor-preview class="position-relative row-detail-1" v-if="row.facadeFirstDecor && row.facadeFirstDecor.previewPath && row.facadeFirstDecor.previewPath.length > 0" :decor-url="`${imageRemoteURL}/${row.facadeFirstDecor.previewPath[0]}`"
            :milling-url="row.facadeFirstMilling && row.facadeFirstMilling.previewPath && row.facadeFirstMilling.previewPath.length > 0 ? `${imageRemoteURL}/${row.facadeFirstMilling.previewPath[0]}`: null"/>
        <!-- Элвиса во vue-expression так и не завезли -->

        <decor-preview class="position-relative row-detail-2" v-if="row.facadeSecondDecor && row.facadeSecondDecor.previewPath && row.facadeSecondDecor.previewPath.length > 0" :decor-url="`${imageRemoteURL}/${row.facadeSecondDecor.previewPath[0]}`"
            :milling-url="row.facadeSecondMilling && row.facadeSecondMilling.previewPath && row.facadeSecondMilling.previewPath.length > 0 ? `${imageRemoteURL}/${row.facadeSecondMilling.previewPath[0]}`: null"/>
        <!-- Дублирование фасада 1 если второго нету -->
        <decor-preview class="position-relative row-detail-2" v-else-if="row.facadeFirstDecor && row.facadeFirstDecor.previewPath && row.facadeFirstDecor.previewPath.length > 0" :decor-url="`${imageRemoteURL}/${row.facadeFirstDecor.previewPath[0]}`"
            :milling-url="row.facadeFirstMilling && row.facadeFirstMilling.previewPath && row.facadeFirstMilling.previewPath.length > 0 ? `${imageRemoteURL}/${row.facadeFirstMilling.previewPath[0]}`: null"/>

        <decor-preview class="position-relative row-detail-accent" v-if="row.facadeAccentDecor && row.facadeAccentDecor.previewPath && row.facadeAccentDecor.previewPath.length > 0" :decor-url="`${imageRemoteURL}/${row.facadeAccentDecor.previewPath[0]}`"
            decor-class="accent-decor"
            :milling-url="row.facadeAccentMilling && row.facadeAccentMilling.previewPath && row.facadeAccentMilling.previewPath.length > 0 ? `${imageRemoteURL}/${row.facadeAccentMilling.previewPath[0]}`: null"/>

        <div class="position-relative row-detail-table-decor" v-if="row.tableDecor && row.tableDecor.previewPath && row.tableDecor.previewPath.length > 0"
            @click="$emit('material-preview-click', 'tableDecor', `${imageRemoteURL}/${row.tableDecor.previewPath[0]}`)">
            <img class="tabletop-image"
                :src="`${imageRemoteURL}/${row.tableDecor.previewPath[0]}`"/>
        </div>

        <div class="position-relative row-detail-wall-panel"
            @click="$emit('material-preview-click','wallPanelDecor', `${imageRemoteURL}/${row.wallPanelDecor.previewPath[0]}`)"
            v-if="row.wallPanelDecor && row.wallPanelDecor.previewPath && row.wallPanelDecor.previewPath.length > 0">
            <img class="wall-panel-image"
                :src="`${imageRemoteURL}/${row.wallPanelDecor.previewPath[0]}`">
        </div>
    </div>
</template>

<script>
    import content_api from '@/api/backend-content';
    import DecorPreview from './DecorPreview.vue';
    import PreviewImage from './preview/PreviewImage';
    export default {
        name: "RowDetails",
        components: {
            'decor-preview': DecorPreview,
            'preview-image': PreviewImage,
        },
        props: {
            row: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                imageRemoteURL: content_api.imagesPath,
                emptyValue: 'Н/Д',
                kitchenModel: null,
            }
        },
        mounted() {
            this.reloadModel();
        },
        watch: {
            row: function() {
                this.reloadModel();
            }
        },
        computed: {
            isManager() {
                return this.$store.state.user?.role.name === 'MANAGER';
            },
            circuitsHidden() {//TODO: убрать дубликаты
                if (this.row == null) {
                    return false;
                }
                let hiddenCircField = this.row.facadeSecondDecor?.vendor?.hidePreviewCircuit;

                return hiddenCircField == null ? true : hiddenCircField;
            },
            kitchenMaterials() {
                if (!this.row) {
                    return null;
                }
                let materialFields = ["facadeFirstDecor","facadeFirstMilling","facadeFirstSurface","facadeFirstPatina","facadeSecondDecor","facadeSecondMilling","facadeSecondSurface","facadeSecondPatina","facadeAccentDecor","facadeAccentMilling","facadeAccentSurface","facadeAccentPatina","tableDecor","wallPanelDecor","cupboardHandle","metalProduct"];
                let materials = Object.fromEntries(materialFields
                    .map(materialField => {
                        let value = this.row[materialField];

                        if (!Array.isArray(value?.previewPath) || value.previewPath.length <= 0) {
                            return null;
                        }
                        return [materialField, `/${content_api.imagesPath}/${value.previewPath[0]}`];
                    })
                    .filter(p => p!= null));
                //замена второго фасада
                if (!this.row.facadeSecondDecor) {
                    materials.facadeSecondDecor   = materials.facadeFirstDecor;
                    materials.facadeSecondSurface = materials.facadeFirstSurface;
                    materials.facadeSecondMilling = materials.facadeFirstMilling;
                    materials.facadeSecondPatina  = materials.facadeFirstPatina;
                }
                return materials;
            }
        },
        methods: {
            reloadModel() {
                if (!this.row?.kitchenStyle?.name) {
                    this.kitchenModel = null;
                }
                content_api.getModel(this.row.kitchenStyle.name)
                           .then(resp => {//TODO: перетащить маппинг в content_api
                               if (resp == null || resp.data == null) {
                                   return;
                               }
                               if (resp.data.mainComp != null && resp.data.mainComp.image != null) {
                                   resp.data.mainComp.image = `/${content_api.imagesPath}/${resp.data.mainComp.image}`;
                               }
                               if (Array.isArray(resp.data.circuits)) {
                                   resp.data.circuits.forEach(c => {
                                       if (c.image) {
                                           c.image = `/${content_api.imagesPath}/${c.image}`;
                                       }
                                   });
                               }
                               this.kitchenModel = resp.data;
                           });
            }
        }
    }
</script>

<style lang="scss">
    .preview-image, .milling-image, .decor-image, .tabletop-image {
        background-size: cover;
        background-repeat: no-repeat;
        min-width: 100px;
        max-width: 150px;
        height: 150px;
    }

    .milling-image {
        z-index: 1;
        position: relative;
    }

    .milling-image, .tabletop-image {
        width: auto;
    }

    .wall-panel-image {
        width: 150px;
        object-fit: cover;
        min-height: 30px;
        max-height: 150px;
    }

    .tabletop-image {
        width: 150px;
        object-fit: cover;
    }

    .decor-image {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.7;
        width: 100%;
    }

    .accent-decor.decor-image {
        opacity: 1.0;
    }
    
    $col-names: preview 1 2 accent table-decor wall-panel;

    @supports not (-moz-appearance: none) {
        @media (min-width: 1550px) {
            /* костыль для хрома, чтобы тот корректно отображал картинку */
            .row-detail-preview .preview-block img:first-child:not(*:root) {
                height: 100%;
                width: auto;
            }
        }
    }
    .row-detail-preview .preview-block img:first-child {
        max-height: 150px;
    }
    @for $i from 1 through length($col-names) {
        $col-name: nth($col-names, $i);
        .row-detail-#{$col-name} {
            min-width: 100px;
            height: 150px;
            justify-self: start;
            grid-column: $i;
            @if $col-name != preview {
                width: min-content;
            }
        }

        .designer-table .row-detail-#{$col-name} {
            grid-column: $i;
        }
    }

    .row-detail-wall-panel {
        display: flex;
    }

    .row-details-container {
        display: grid;
        grid-template-columns: 17fr repeat(5, 12fr) 8fr 5fr 10fr;
        align-items: center;
    }

    .designer-table .row-details-container {
        grid-template-columns: 2.8fr repeat(5, 2fr) 1.36fr 0.84fr 1fr;
    }

    .row-details-container > * {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
    @media (max-width: 1250px) {

        @for $i from 1 through length($col-names) {
            $col-name: nth($col-names, $i);
            .row-detail-#{$col-name} {
                min-width: 80px;
                height: 120px;
            }

        }
        
        .row-details-container {
            /*grid-template-columns: 5% 7% repeat(4, min-content) 10% 10% 5% 5%;*/
            /*/column-gap: 0.75rem;*/
        }

        .row-details-container > * {
            margin-left: 0.375rem;
            margin-right: 0.375rem;
        }
        /*
           .row-details-container-alt {
           grid-template-columns: 10% repeat(4, min-content) 10% 10% 5% 5%;
           column-gap: 1.5rem;
           }*/

        .row-detail, .row-detail-wall-panel {
            width: 80px;
            height: 120px;
        }

        .row-detail-wall-panel {
            width: 80px;
        }

        .milling-image, .decor-image, .tabletop-image {
            min-width: 80px;
            max-width: 120px;
            height: 120px;
        }

        .wall-panel-image {
            width: 80px;
            min-height: 80px;
            max-height: 120px;
        }

        .tabletop-image {
            width: 80px;
        }

    }
</style>
