<template>
    <b-card class="main-card">
        <Toolbar
                :show-create-button="isManager"
                :show-copy-button="isManager"
                :show-delete-button="isManager"
                :show-edit-button="isManager"
                :show-filter="isManager"
                :show-material-filters="isManager"
                :show-price-change="isManager"
                :price-change-button-disabled="selectedRows.length < 1 && !isAllSelected"
                :material-replacement-button-disabled="false"
                :copy-button-disabled="selectedRows.length < 1"
                :delete-button-disabled="selectedRows.length < 1"
                @delete-clicked="deleteSelectionQuestion"
                @copy-clicked="copySelection"
                @replacement-clicked="openMaterialReplacement"
                @apply-filter="applyFilter"
                @on-price-multiplier-changed="changePrice"
                @price-multiplier-all-selection-changed="handleSelectionChange"
        />
        <b-table fixed responsive id="combination-table" ref="combinationTable" striped show-empty
            details-td-class="row-details"
            :class="isManager ? '' : 'designer-table'"
            :fields="fields"
            :items="getTableData"
            @row-clicked="onRowClicked"
            @row-selected="onRowSelected"
            @refreshed="onTableRefresh"
            no-select-on-click
            :per-page="perPage"
            :current-page="page"
            select-mode="multi"
            selectable
            :busy="tableBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="(field, index) in scope.fields"
                    :key="field.key"
                    :class="`kitchen-col-${index + 1}`"
                >
            </template>
            <template #empty>
                <h4 class="text-center">Данные не найдены</h4>
            </template>
            <template #table-busy>
                <div class="text-center text-dark my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Загрузка...</strong>
                </div>
            </template>
            <template #head(check)>
                <b-checkbox size="lg" v-model="allSelected"></b-checkbox>
            </template>
            <template #cell(check)="data">
                <b-checkbox size="lg" v-model="data.rowSelected" @change="(e) => toggleSelection(data,e)"></b-checkbox>
            </template>
            <template #cell(preview)>
                
            </template>
            <template #cell(facadeFirst)="data">
                <div>
                    <material-name v-if="data.item.facadeFirstMilling" class="material-name-item"
                        :material="data.item.facadeFirstMilling" />
                    <material-name v-if="data.item.facadeFirstDecor" class="material-name-item"
                        :material="data.item.facadeFirstDecor" />
                    <material-name v-if="data.item.facadeFirstPatina" class="material-name-item"
                        :material="data.item.facadeFirstPatina" />
                </div>
            </template>
            <template #cell(facadeSecond)="data">
                <div v-if="data.item.facadeSecondDecor">
                    <material-name v-if="data.item.facadeSecondMilling" class="material-name-item"
                        :material="data.item.facadeSecondMilling" />
                    <material-name v-if="data.item.facadeSecondDecor" class="material-name-item"
                        :material="data.item.facadeSecondDecor" />
                    <material-name v-if="data.item.facadeSecondPatina" class="material-name-item"
                        :material="data.item.facadeSecondPatina" :prefix="патина " />
                </div>
                <div v-else>
                    <material-name v-if="data.item.facadeFirstMilling" class="material-name-item"
                        :material="data.item.facadeFirstMilling" />
                    <material-name v-if="data.item.facadeFirstDecor" class="material-name-item"
                        :material="data.item.facadeFirstDecor" />
                    <material-name v-if="data.item.facadeFirstPatina" class="material-name-item"
                        :material="data.item.facadeFirstPatina" />
                </div>
            </template>
            <template #cell(facadeAccent)="data">
                <div>
                    <material-name v-if="data.item.facadeAccentMilling" class="material-name-item"
                        :material="data.item.facadeAccentMilling" />
                    <material-name v-if="data.item.facadeAccentDecor" class="material-name-item"
                        :material="data.item.facadeAccentDecor" />
                    <material-name v-if="data.item.facadeAccentPatina" class="material-name-item"
                        :material="data.item.facadeAccentPatina" :prefix="патина " />
                </div>
            </template>
            <template #cell(tableDecor)="data">
                <material-name v-if="data.item.tableDecor" class="material-name-item"
                    :material="data.item.tableDecor" :prefix="data.item.tableDecor.vendor ? data.item.tableDecor.vendor.name + ' ' : emptyValue" />
            </template>
            <template #cell(wallPanel)="data">
                <material-name v-if="data.item.wallPanelDecor" class="material-name-item"
                    :material="data.item.wallPanelDecor" :prefix="data.item.wallPanelDecor.vendor ? data.item.wallPanelDecor.vendor.name + ' ' : emptyValue" />
            </template>
            <template #cell(pricePerMeter)="data">
                <span>{{ data.item.pricePerMeter ? data.item.pricePerMeter + ' тыс. руб.' : emptyValue }}</span>
            </template>
            <template #cell(availability)="data">
                <div class="text-center">
                    <b-icon v-if="data.item.availability === 'AVAILABLE'" icon="check-circle-fill" font-scale="2" variant="success"></b-icon>
                    <b-icon v-else-if="data.item.availability === 'UNAVAILABLE'" icon="x-circle-fill" font-scale="2" variant="danger"></b-icon>
                    <b-icon v-else-if="data.item.availability === 'STOP_SALES'" icon="exclamation-circle-fill" font-scale="2" variant="warning"></b-icon>
                </div>
            </template>
            <template #cell(actions)="data">
                <div class="text-center">
                    <b-link :to="`/${data.item.id}`" class="btn btn-secondary m-1" title="Редактировать" v-if="isManager"><b-icon icon="pencil" /></b-link>
                    <b-button class="m-1" title="Скрыть" @click="hideRow(data)"><b-icon icon="eye-slash" /></b-button>
                </div>
            </template>
            <template #row-details="row">
                <RowDetails :row="row.item" @material-preview-click="showMaterialPreview"></RowDetails>
            </template>
        </b-table>
        <div class="d-flex">
            <multiselect v-model="pageSizeSelection"
                class="mw-25 mb-3 align-self-center ml-auto"
                :multiple="false"
                :allow-empty="false"
                :searchable="false"
                :show-labels="false"
                track-by="value"
                :options="pageSizesList" label="name">
                <template #singleLabel="{ option }">
                    Элементов на страницу: {{option.name}}
                </template>
            </multiselect>
            <b-pagination-nav :link-gen="linkGen" :number-of-pages="rows == 0 ? 1 : Math.ceil(rows / perPage)" align="right" size="lg"
                :per-page="perPage" aria-controls="combination-table" use-router></b-pagination-nav>
        </div>
        <b-modal size="xl" ref="materialReplacementModal" title="Замена материала"
            :hide-footer="true">
            <material-replacement-form @replacement-failure="materialReplacementFailure" @replacement-succesful="materialReplacementSubmitted" />
        </b-modal>
        <b-modal ref="materialPreviewModal" size="xl" :hide-footer="true" body-class="d-flex" title="Превью материала">
            <img :src="materialPreview.url" @error="materialPreviewError" class="img-fluid mx-auto" />
        </b-modal>
    </b-card>
</template>

<script>
    import api from '@/api/backend-api';
    import RowDetails from "./RowDetails";
    import Toolbar from "@/components/Toolbar";
    import KitchenMaterialReplacementForm from '@/components/material/KitchenMaterialReplacementForm';
    import MaterialName from '@/components/MaterialName.vue';
    import Multiselect from 'vue-multiselect';
    import {mapActions} from 'vuex';

    export default {
        name: "CombinationTable",
        components: {Toolbar, RowDetails,
                     'material-replacement-form': KitchenMaterialReplacementForm,
                     'multiselect': Multiselect,
                     'material-name': MaterialName
        },
        props: {
            page: {
                type: Number,
                required: false,
                default: 1
            },
            perPage: {
                type: Number,
                required: false,
                default: 15
            }
        },
        data() {
            return {
                pageSizesList: [
                    { name: '5', value: 5},
                    { name: '15', value: 15},
                    { name: '25', value: 25},
                    { name: '50', value: 50},
                    { name: '100', value: 100},
                    { name: 'Все', value: 0xFFFFFFFF}//чо бы и нет
                ],
                sortBy: 'createdTime',
                sortDesc: true,
                rowsCount: 0,
                selectedRows: [],
                filter: [],
                emptyValue: '',
                tableBusy: false,
                materialPreview: {
                    url: null,
                    fallbackUrl: null,
                },
                isAllSelected: false
            }
        },
        created() {
            this.loadPageSizes()
                .then(data => {
                    this.pageSizesList = data;
                });
        },
        methods: {
            handleSelectionChange(isAllSelected) {
                this.isAllSelected = isAllSelected;
            },
            getOrDefault(obj, key, defaultValue = '', prefix = '') {
                return obj ? (obj[key] ? prefix + obj[key] : defaultValue) : defaultValue
            },
            onRowClicked(row) {
                this.$set(row, '_showDetails', !row._showDetails);
            },
            onTableRefresh() {
                this.$refs.combinationTable.localItems.forEach(row => {
                    this.$set(row, '_showDetails', true);
                });
            },
            getTableData(context) {
                this.pageOffset = 0; // сброс отступа при загрузке страницы
                this.tableBusy = true
                return api.getTableData(context.perPage, context.currentPage - 1, context.sortBy, context.sortDesc, this.filter)
                          .then(response => {
                              this.rowsCount = response.data.totalElements;
                              return response.data.content;
                          })
                          .finally(() => {
                              this.tableBusy = false
                          });
            },
            onRowSelected(rows) {
                this.selectedRows = rows;
            },
            toggleSelection(item, value) {
                if (value) {
                    item.selectRow();
                }
                else {
                    item.unselectRow();
                }
            },
            openEdit(kitchenObject) {
                if (kitchenObject == null || kitchenObject.id == null) {
                    return;
                }
                this.$router.push(`/${kitchenObject.id}`);
            },
            deleteSelection() {
                if (this.selectedRows.length < 1) {
                    return;
                }
                let selIds = this.selectedRows.map(r => r.id).filter(id => id != null);

                api.deleteMultipleKitchens(selIds)
                   .then(() => {
                       this.$refs.combinationTable.refresh();
                       this.$bvToast.toast('Выбранные кухни были успешно удалены', {
                           title: 'Удаление',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                   })
                   .catch(() => {
                       this.$bvToast.toast('Произошла ошибка при удалении выбранных моделей кухни', {
                           title: 'Ошибка',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                   });
            },
            deleteSelectionQuestion() {
                if (this.selectedRows.length < 1) {
                    return;
                }
                this.$bvModal.msgBoxConfirm(`Вы действительно хотите удалить выбранные кухни (Выбрано записей: ${this.selectedRows.length})?`, {
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    title: 'Подтвердите действие'
                })
                    .then((value) => {
                        if (value) {
                            this.deleteSelection();
                        }
                    });
            },
            copySelection(e, copyCount) {
                if (this.selectedRows.length < 1) {
                    return;
                }
                let selIds = this.selectedRows.map(r => r.id).filter(id => id != null);
                api.cloneMultipleKitchens(selIds, copyCount)
                   .then(() => {
                       this.$refs.combinationTable.refresh();
                       this.$bvToast.toast(`Было успешно скопировано записей: ${selIds.length * copyCount}`, {
                           title: 'Копирование',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                   })
                   .catch(() => {
                       this.$bvToast.toast('Произошла ошибка при копировании выбранных моделей кухни', {
                           title: 'Ошибка',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                   });
            },
            applyFilter(data, resetPage = true, filterChanged = true) {
                this.filter = data;
                this.filter.ignoreIds = [];
                if (resetPage && filterChanged && this.page !== 1) {
                    this.$router.push(this.linkGenFull(1, this.perPage));
                }
                else if (this.$refs?.combinationTable) {
                    this.$refs.combinationTable.refresh();
                }
            },
            openMaterialReplacement() {
                this.$refs.materialReplacementModal.show();
            },
            materialReplacementSubmitted() {
                this.$refs.materialReplacementModal.hide();
                this.$bvToast.toast('Замена материалов выполнена успешно', {
                    title: 'Замена материалов',
                    autoHideDelay: 5000,
                    appendToast: true,
                })
                this.$refs.combinationTable.refresh();
            },
            materialReplacementFailure() {
                this.$bvToast.toast('Произошла ошибка при замене материалов кухни', {
                    title: 'Ошибка',
                    autoHideDelay: 5000,
                    appendToast: true,
                })
            },
            hideRow(row) {
                if (this.filter.ignoreIds.indexOf(row.item.id) === -1) {
                    this.filter.ignoreIds.push(row.item.id);
                }
                this.$refs.combinationTable.refresh();
            },
            linkGenFull(pageNumber, perPage) {
                if (pageNumber == null) {
                    pageNumber = this.page;
                }
                if (perPage == null) {
                    perPage = this.perPage;
                }
                let query = {};
                if (pageNumber !== 1) {
                    query.page = pageNumber;
                }
                if (perPage !== 15) {
                    query.perPage = perPage;
                }
                return {
                    query
                }
            },
            linkGen(pageNumber) {
                return this.linkGenFull(pageNumber);
            },
            linkGenPerPage(perPage) {
                return this.linkGenFull(undefined, perPage);
            },
            showMaterialPreview(materialType, previewUrl) {
                //TODO вытащить суффикс в ассеты
                this.materialPreview.url = previewUrl.replace(/(.*\/)*([^/.]+)(\..*)$/, "$1$2_big$3");
                this.materialPreview.fallbackUrl = previewUrl;
                this.$refs.materialPreviewModal.show();
            },
            materialPreviewError(/*e*/) {
                if (this.materialPreview.url != null &&
                    this.materialPreview.url !== this.materialPreview.fallbackUrl) {
                    this.materialPreview.url = this.materialPreview.fallbackUrl;
                }
            },
            changePrice(priceMultiplier) {
                if (this.isAllSelected) {
                    api.updatePricesByFilter(this.filter, priceMultiplier)
                        .then(() => location.reload());
                } else {
                    api.updatePricesById(this.selectedRows, priceMultiplier)
                        .then(() => location.reload());
                }
            },
            ...mapActions(['loadPageSizes'])
        },
        computed: {
            user(){
                return this.$store.state.user;
            },
            isManager() {
                return this.user != null && this.user.role.name === 'MANAGER';
            },
            rows() {
                return this.rowsCount
            },
            pageSizeSelection: {
                get() {
                    let szOption = this.pageSizesList.find(opt => opt.value === this.perPage);
                    if (szOption == null) {
                        return {
                            name: String(this.perPage),
                            value: this.perPage
                        };
                    }
                    return szOption;
                },
                set(option) {
                    let changeEnvVar = (option.value !== this.perPage);
                    if (changeEnvVar) {
                        this.$router.push(this.linkGenPerPage(option.value));
                    }
                }
            },
            allSelected: {
                set(value) {
                    if (value) {
                        this.$refs.combinationTable.selectAllRows();
                    }
                    else if (this.$refs.combinationTable.localItems.length == this.selectedRows.length) {
                        this.$refs.combinationTable.clearSelected();
                    }
                },
                get() {
                    return (this.$refs.combinationTable == null ? -1 : this.$refs.combinationTable.localItems.length) == this.selectedRows.length;
                }
            },
            fields() {
                if (this.isManager) {
                    return [
                        { key: 'check', label: '#' },
                        { key: 'preview', label: 'Превью' },
                        { key: 'facadeFirst', label: 'Фасад 1' },
                        { key: 'facadeSecond', label: 'Фасад 2' },
                        { key: 'facadeAccent', label: 'Фасад-Акцент' },
                        { key: 'tableDecor', label: 'Столешница' },
                        { key: 'wallPanel', label: 'Панель' },
                        { key: 'pricePerMeter', label: 'Цена пог.метра', sortable: true },
                        { key: 'availability', label: 'Доступно', sortable: true },
                        { key: 'actions', label: 'Действия', thClass: 'text-center'  }
                    ]
                }
                return [
                    { key: 'preview', label: 'Превью'},
                    { key: 'facadeFirst', label: 'Фасад 1' },
                    { key: 'facadeSecond', label: 'Фасад 2' },
                    { key: 'facadeAccent', label: 'Фасад-Акцент' },
                    { key: 'tableDecor', label: 'Столешница' },
                    { key: 'wallPanel', label: 'Панель' },
                    { key: 'pricePerMeter', label: 'Цена пог.метра', sortable: true },
                    { key: 'availability', label: 'Доступно', sortable: true },
                    { key: 'actions', label: 'Действия', thClass: 'text-center' }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    .row {
        margin-bottom: 15px;
    }
    .text-success {
        color: #bfed2b !important;
    }
    .text-danger {
        color: #cb1c00 !important;
    }

    $col-sizes: 3% 14% 12% 12% 12% 12% 12% 8% 5% 10%;
    $col-sizes-designer: 17.5% 12.5% 12.5% 12.5% 12.5% 12.5% 8.5% 5.25% 6.25%;
    /*$col-sizes: 5% 7% min-content min-content min-content min-content 10% 10% 5% 5%;*/
    @for $i from 1 through length($col-sizes) {
        $size: nth($col-sizes, $i);
        .kitchen-col-#{$i} {
            width: $size;
        }
    }
    
    @for $i from 1 through length($col-sizes-designer) {
        $size: nth($col-sizes-designer, $i);
        .designer-table .kitchen-col-#{$i} {
            width: $size;
        }
    }
</style>
<style>
    .main-card {
        min-width: 75rem !important;
    }
    .page-item.active .page-link {
        background-color: #fff !important;
        border-color: #fff !important;
        color: #27aaf2 !important;
    }
    .page-link {
        border-color: #fff !important;
        color: #1c1d2b !important;
    }
    th {
        white-space: nowrap;
        vertical-align: middle;
    }
    td {
        vertical-align: middle !important;
    }

    .row-details {
        padding: .75rem 0 .75rem 0 !important;
    }

    .mw-25 {
        max-width: 25%;
    }

</style>
