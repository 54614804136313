<template>
  <b-container>
    <ChangePasswordComponent
        :login="login"/>
  </b-container>
</template>

<script>
import ChangePasswordComponent from "@/components/ChangePasswordComponent";

export default {
  name: "ChangePassword",
  props: {
    login: {
      type: String,
      required: true
    }
  },
  components: {ChangePasswordComponent}
}
</script>

<style scoped>

</style>