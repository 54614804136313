import Vue from 'vue'
import VueRouter from 'vue-router'
import KitchenEditPage from '@/views/KitchenEditPage.vue';
import CombinationTablePage from '@/views/CombinationTablePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import TagEditPage from '@/views/TagEditPage.vue';
import KitchenPreviewPage from "@/views/KitchenPreviewPage";
import HandlePage from "@/views/HandlePage";
import store from '@/store';
import ShowUserPage from "@/views/ShowUserPage";
import CreateUserPage from "@/views/CreateUserPage";
import EditUserPage from "@/views/EditUserPage";
import ChangePasswordPage from "@/views/ChangePasswordPage";
import BackendErrorPage from "@/views/BackendErrorPage";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'CombinationTablePage',
        component: CombinationTablePage,
        props: route => ({ page: route.query.page == null ? 1 : parseInt(route.query.page),
                           perPage: route.query.perPage == null ? undefined : parseInt(route.query.perPage)
                         })
    },
    {
	path: '/tags',
	name: 'TagEditPage',
	component: TagEditPage
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage
    },
    {
        path: '/handles',
        name : 'HandlePage',
        component: HandlePage,
    },
    {
        path: '/create',
        component: KitchenEditPage,
        props: { creating: true },
        meta: { title: 'Создание кухни'}
    },
    {
        path: '/users',
        name: 'ShowUserPage',
        component: ShowUserPage,
    },
    {
        path: '/users/create',
        name: 'CreateUserPage',
        component: CreateUserPage,
    },
    {
        path: '/users/:login',
        name: 'EditUserPage',
        component: EditUserPage,
        props: (route) => ({
            login: route.params.login,
        })
    },
    {
        path: '/users/:login/password',
        name: 'ChangePasswordPage',
        component: ChangePasswordPage,
        props: (route) => ({
            login: route.params.login,
        })
    },
    {
        path: '/error',
        name: 'BackendErrorPage',
        component: BackendErrorPage,
    },
    {
        path: '/:id/preview',
        component: KitchenPreviewPage,
        props: (route) => ({
            id: parseInt(route.params.id)
        })
    },
    {
        path: '/:id',
        component: KitchenEditPage,
        props: route => ({
            id: parseInt(route.params.id),
            creating: false }),
        meta: { title: 'Редактирование кухни'}
    },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
    const managerOnlyPages = [/\/[0-9]+/, /\/create/ , /\/users/];//пока что просто компоненты для редактирования
    let managerAuthorizationRequired = managerOnlyPages.some(reg => reg.test(to.path));
    if (managerAuthorizationRequired && store.state.user != null && store.state.user.role.name !== 'MANAGER') {
        return next(false);
    }
    next();
});

export default router
