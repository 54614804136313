<template>
    <div>
        <div class="my-2" v-for="(replacement, index) in materialReplacements" v-bind:key="index * 2">
            <b-row>
                <b-col>
                    <multiselect
                        class="mb-2"
                        v-model="replacement.materialField"
                        @input="(ev) => materialTypeChanged(ev, index)"
                        label="title"
                        :clear-on-select="false"
                        placeholder="Выберите тип материала"
                        :show-labels="false"
                        :options="$options.KITCHEN_MATERIAL_FIELDS"></multiselect>
                </b-col>
                <b-col cols="auto" class="align-items-start d-flex">
                    <b-button class="mx-2" @click="addNewMaterialEntry(index)">+</b-button>
                    <b-button class="mx-2" @click="deleteMaterialEntry(index)">×</b-button>
                </b-col>
            </b-row>
            <template v-if="replacement.materialField != null">
                <material-replacement
                    v-if="!replacement.materialField.affectsOtherMaterials"
                    :old-material="replacement.oldMaterial"
                    :new-material="replacement.newMaterial"
                    @replace-old-material="replaceMaterial(replacement, 'oldMaterial')"
                    @replace-new-material="replaceMaterial(replacement, 'newMaterial')"
                    @drop-old-material="() => replacement.oldMaterial = null"
                    @drop-new-material="() => replacement.newMaterial = null"
                >
                </material-replacement>
                <decoration-replacement
                    v-else
                    :old-material="replacement.oldMaterial"
                    :decor-material="replacement.decorMaterial"
                    :milling-material="replacement.millingMaterial"
                    :surface-material="replacement.surfaceMaterial"
                    :patina-material="replacement.patinaMaterial"
                    @replace-old-material="replaceMaterial(replacement, 'oldMaterial')"
                    @replace-decor-material="replaceMaterial(replacement, 'decorMaterial')"
                    @replace-milling-material="replaceMaterial(replacement, 'millingMaterial', 'MILLING')"
                    @replace-surface-material="replaceMaterial(replacement, 'surfaceMaterial', 'SURFACE')"
                    @replace-patina-material="replaceMaterial(replacement, 'patinaMaterial', 'PATINA')"
                    @drop-old-material="() => replacement.oldMaterial = null"
                    @drop-decor-material="() => replacement.decorMaterial = null"
                    @drop-milling-material="() => replacement.millingMaterial = null"
                    @drop-patina-material="() => replacement.patinaMaterial = null"
                    @drop-surface-material="() => replacement.surfaceMaterial = null"
                >
                </decoration-replacement>
            </template>
        </div>
        <b-button  v-if="materialReplacements.length == 0" @click="addNewMaterialEntry">Добавить запись для замены</b-button>
        <h4 class="mt-2">Исключения</h4>
        <div class="my-2" v-for="(exclusion, index) in materialExclusions" v-bind:key="index * 2 + 1">
            <b-row>
                <b-col>
                    <multiselect
                        class="mb-2"
                        v-model="exclusion.materialField"
                        @input="(ev) => exclusionMaterialTypeChanged(ev, index)"
                        label="title"
                        :clear-on-select="false"
                        placeholder="Выберите тип материала"
                        :show-labels="false"
                        :options="$options.KITCHEN_MATERIAL_FIELDS"></multiselect>
                </b-col>
                <b-col cols="auto" class="align-items-start d-flex">
                    <b-button class="mx-2" @click="addNewExclusionEntry(index)">+</b-button>
                    <b-button class="mx-2" @click="deleteExclusionEntry(index)">×</b-button>
                </b-col>
            </b-row>
            <material-card
                v-if="exclusion.materialField != null"
                :material="exclusion.material"
                @replace-material="replaceMaterial(exclusion, 'material')"
                @drop-material="() => exclusion.material = null"
            />
        </div>
        <b-button  v-if="materialExclusions.length == 0" @click="addNewExclusionEntry">Добавить запись для исключения</b-button>
        <b-button block class="mt-2" :disabled="!formValid" @click="submitReplacement">Выполнить замену</b-button>
        <b-modal size="xl" ref="materialPickerModal" title="Выбор материала"
            :hide-footer="true"
        >
            <multiselect
                v-model="selectedVendor"
                label="name"
                :options="materialVendors"
                :disabled="selectedMaterial != null && selectedMaterialType != null && selectedMaterial.materialField.field !== selectedMaterialType"
                placeholder="Выбрать поставщика"
                :show-labels="false"/>
            <b-form-input v-model="filterMaterialName" placeholder="Название материала" class="mt-2" @input="$refs.materialPicker.refresh()" />
            <kitchen-material-picker @card-clicked="onCardPicked"
                :currentPage="currentPage"
                :perPage="perPage"
                :items="loadMaterials"
                :imageSource="(item) => item.previewPath != null && item.previewPath.length > 0 ?  `${imageRemoteURL}/${item.previewPath[0]}` : stubImageRemoteURL"
                :cardTitle="(item) => item.name"
                ref="materialPicker" >
                <template #image-overlay="data">
                    <b-badge  :variant="materialStatusVariant(data.item.availability)" class="badge-overlay">{{materialStatusText(data.item.availability)}}</b-badge>
                </template>
            </kitchen-material-picker>
            <b-pagination v-model="currentPage" :total-rows="totalElements" align="right" size="lg"
                :per-page="perPage"></b-pagination>
        </b-modal>
    </div>
</template>
<script>
    import MaterialReplacement from './MaterialReplacement';
    import DecorationReplacement from './DecorationReplacement';
    import MaterialCard from './MaterialCard';
    import Multiselect from 'vue-multiselect';
    import KitchenMaterialPicker from '@/components/KitchenMaterialPicker';
    import api from '@/api/backend-api';

    const AVAILABILITY_STATUS = { //TODO: вытащить в отдельный модуль
        UNAVAILABLE: {
            variant: 'danger',
            text: 'Снят'
        },
        STOP_SALES: {
            variant: 'warning',
            text: 'В СТОПе'
        },
        AVAILABLE: {
            variant: 'success',
            text: 'Доступно'
        },
    };

    export default {
        components: {
            'kitchen-material-picker': KitchenMaterialPicker,
            'material-replacement': MaterialReplacement,
            'decoration-replacement': DecorationReplacement,
            'material-card': MaterialCard,
            'multiselect': Multiselect,
        },
        KITCHEN_MATERIAL_FIELDS: [
            {field: "facadeFirstDecor", title: 'Фасад 1 декор', materialType: 'FACADE_DECORATION', affectsOtherMaterials: true, destRequired: true},
            {field: "facadeFirstMilling", title: 'Фасад 1 фрезеровка', materialType: 'MILLING', affectsOtherMaterials: false},
            {field: "facadeFirstSurface", title: 'Фасад 1 поверхность', materialType: 'SURFACE', affectsOtherMaterials: false},
            {field: "facadeFirstPatina", title: 'Фасад 1 патина', materialType: 'PATINA', affectsOtherMaterials: false},
            {field: "facadeSecondDecor",   title: 'Фасад 2 декор', materialType: 'FACADE_DECORATION', affectsOtherMaterials: true},
            {field: "facadeSecondMilling", title: 'Фасад 2 фрезеровка', materialType: 'MILLING', affectsOtherMaterials: false},
            {field: "facadeSecondSurface", title: 'Фасад 2 поверхность', materialType: 'SURFACE', affectsOtherMaterials: false},
            {field: "facadeSecondPatina",  title: 'Фасад 2 патина', materialType: 'PATINA', affectsOtherMaterials: false},
            {field: "facadeAccentDecor",   title: 'Фасад-акцент декор', materialType: 'FACADE_DECORATION', affectsOtherMaterials: true},
            {field: "facadeAccentMilling", title: 'Фасад-акцент фрезеровка', materialType: 'MILLING', affectsOtherMaterials: false},
            {field: "facadeAccentSurface", title: 'Фасад-акцент поверхность', materialType: 'SURFACE', affectsOtherMaterials: false},
            {field: "facadeAccentPatina",  title: 'Фасад-акцент патина', materialType: 'PATINA', affectsOtherMaterials: false},
            {field: "tableDecor", title: 'Столешница', materialType: 'TABLETOP_DECORATION', affectsOtherMaterials: false, destRequired: true},
            {field: "wallPanelDecor",title: 'Стеновая панель', materialType: 'WALL_DECORATION', affectsOtherMaterials: false, destRequired: true},
            {field: "cupboardHandle", title: 'Ручки', materialType: 'HANDLES', affectsOtherMaterials: false},
            {field: "metalProduct", title: 'Металлоизделия', type: 'METALWARE', affectsOtherMaterials: false}
        ],
        mounted() {
            this.loadVendors();
        },
        data() {
            return {
                stubImageRemoteURL: api.stubImagePath,
                imageRemoteURL: api.imagesPath,
                materialReplacements: [],
                materialExclusions: [],
                materialVendors: [],
                selectedMaterial: null,
                selectedMaterialField: null,
                selectedMaterialType: null,
                selectedVendor: null,
                filterMaterialName: null,
                // для выбора материалов
                currentPage: 1,
                perPage: 10,
                totalElements: 0
            };
        },
        computed: {
            formValid() {
                if (this.materialReplacements.length == 0) {
                    return false;
                }
                for (let rep of this.materialReplacements) {
                    if (rep.materialField == null) {
                        return false;
                    }
                    else if (rep.oldMaterial == null) {
                        return false;
                    }
                    else if (/*rep.materialField.destRequired &&*/ (rep.materialField.affectsOtherMaterials ? rep.decorMaterial == null : rep.newMaterial == null)) {
                        return false;
                    }
                }
                for (let ex of this.materialExclusions) {
                    if (ex.materialField == null) {
                        return false;
                    }
                    else if (ex.material == null) {
                        return false;
                    }
                }
                return true;
            },
            
            vendorFilter() {
                let vendorFilter = {};
                let materialType;
                if (this.selectedMaterialType != null) {
                    materialType = this.selectedMaterialType;
                }
                else if (this.selectedMaterial?.materialField != null) {
                    materialType = this.selectedMaterial.materialField.materialType;
                }
                if (materialType) {
                    vendorFilter.materials = {};
                    vendorFilter.materials.materialType = materialType;
                }
                return vendorFilter;
            },
            materialFilter() {
                let filterData = {};
                if (this.selectedMaterial == null) {
                    return filterData;
                }
                if (this.selectedMaterialType != null) {
                    filterData.materialType = this.selectedMaterialType;
                }
                else if (this.selectedMaterial.materialField != null) {
                    filterData.materialType = this.selectedMaterial.materialField.materialType;
                }
                if (this.selectedVendor != null && this.selectedVendor.id != null) {
                    filterData.vendor = {
                        id: this.selectedVendor.id
                    };
                }
                if (this.filterMaterialName != null) {
                    filterData.name = this.filterMaterialName;
                }
                return filterData;
            },
        },
        watch: {
            selectedVendor: function() {
                if (this.$refs.materialPicker != null) {
                    this.$refs.materialPicker.refresh();
                }
            }
        },
        methods: {
            deleteExclusionEntry(index) {
                this.materialExclusions.splice(index, 1);
            },
            addNewExclusionEntry(index) {
                let materialObj = {
                    material: null,
                    materialField: null,
                };
                if (index == null) {
                    this.materialExclusions.push(materialObj);
                }
                else {
                    this.materialExclusions.splice(index + 1, 0, materialObj);
                }  
            },
            deleteMaterialEntry(index) {
                this.materialReplacements.splice(index, 1);
            },
            addNewMaterialEntry(index) {
                let materialObj = {
                    oldMaterial: null,
                    newMaterial: null, // при обычной замене материала
                    decorMaterial: null, // для полной замены материала
                    millingMaterial: null,
                    surfaceMaterial: null,
                    patinaMaterial: null,
                    materialField: null,
                };
                if (index == null) {
                    this.materialReplacements.push(materialObj);
                }
                else {
                    this.materialReplacements.splice(index + 1, 0, materialObj);
                }
                
            },
            replaceMaterial(materialValue, materialField, additionalMatType) {
                this.selectedMaterial = materialValue;
                this.selectedMaterialField = materialField;
                this.selectedMaterialType = additionalMatType;
                // сброс фильтров
                if (this.selectedMaterial.materialField.affectsOtherMaterials && this.selectedMaterialField !== 'decorMaterial' && this.selectedMaterialField !== 'oldMaterial') {
                    // закрытие возможности выбора материала
                    if (this.selectedMaterial.decorMaterial != null) {
                        this.selectedVendor = this.selectedMaterial.decorMaterial.vendor;
                    }
                    else {
                        console.warn('Необходимо сперва выбрать материал декора');
                    }
                }
                else {
                    this.loadVendors();
                    this.selectedVendor = null;
                }
                this.currentPage = 1;
                this.$refs['materialPickerModal'].show();
            },
            onCardPicked(material) {
                if (this.selectedMaterial == null || this.selectedMaterialField == null) {
                    return;
                }
                this.selectedMaterial[this.selectedMaterialField] = material;
                if (material != null && this.selectedMaterialField === 'decorMaterial') {
                    // сброс ВСЕХ материалов без нужного вендора
                    if (this.selectedMaterial.millingMaterial != null) {
                        this.selectedMaterial.millingMaterial = this.selectedMaterial.millingMaterial.vendor.id === this.selectedMaterial.decorMaterial.vendor.id ? this.selectedMaterial.millingMaterial : null;
                    }
                    if (this.selectedMaterial.surfaceMaterial != null) {
                        this.selectedMaterial.surfaceMaterial = this.selectedMaterial.surfaceMaterial.vendor.id === this.selectedMaterial.decorMaterial.vendor.id ? this.selectedMaterial.surfaceMaterial : null;
                    }
                    if (this.selectedMaterial.patinaMaterial != null) {
                        this.selectedMaterial.patinaMaterial = this.selectedMaterial.patinaMaterial.vendor.id === this.selectedMaterial.decorMaterial.vendor.id ? this.selectedMaterial.patinaMaterial : null;
                    }
                }
                this.$refs['materialPickerModal'].hide();
            },
            exclusionMaterialTypeChanged(ev, index) {
                let material = this.materialExclusions[index];
                if (material.materialField == null) {
                    return;
                }
                if (material.material != null && material.materialField.materialType !== material.material.materialType) {
                    material.material = null;
                }
            },
            materialTypeChanged(ev, index) {
                let material = this.materialReplacements[index];
                if (material.materialField == null) {
                    return;
                }
                if (material.oldMaterial != null && material.materialField.materialType !== material.oldMaterial.materialType) {
                    material.oldMaterial = null;
                }
                if (material.materialField.affectsOtherMaterials) {
                    if (material.newMaterial != null && material.decorMaterial == null) {
                        if (material.newMaterial.materialType !== material.materialField.materialType) {
                            material.newMaterial = null;
                        }
                        else {
                            material.decorMaterial = material.newMaterial;
                            material.newMaterial = null;
                        }
                    }
                }
                else {
                    if (material.newMaterial == null && material.decorMaterial != null) {
                        if (material.decorMaterial.materialType === material.materialField.materialType) {
                            material.newMaterial = material.decorMaterial;
                        }
                        material.decorMaterial = null;
                    }
                    else if (material.newMaterial != null && material.newMaterial.materialType !== material.materialField.materialType) {
                        material.newMaterial = null;
                    }
                }
            },
            submitReplacement() {
                let replacements = this.materialReplacements;
                let exclusions = this.materialExclusions;
                api.replaceMaterials(replacements, exclusions)
                   .then(() => {
                       this.$emit('replacement-succesful', this);
                   })
                   .catch(() => {
                       this.$emit('replacement-failure', this);
                   });
            },
            
            materialStatusVariant(status) {
                return AVAILABILITY_STATUS[status]?.variant;
            },
            materialStatusText(status) {
                return AVAILABILITY_STATUS[status]?.text;
            },
            loadMaterials(context){
                return api.getMaterialData(context.perPage, context.currentPage - 1, 'name', 'asc', this.materialFilter)
                          .then(resp => {
                              this.totalElements = resp.data.totalElements;
                              return resp.data.content;
                          });
            },
            loadVendors(){
                return api.getVendorData(undefined, undefined, 'name', 'asc', this.vendorFilter)
                          .then(resp => {
                              this.materialVendors = resp.data.content;
                          });
            }
        }
    }
</script>
