<template>
    <div>
        <img class="milling-image"
            v-if="millingUrl != null && millingUrl !== ''"
            :src="millingUrl">
        <img :class="'decor-image' + (decorClass ? (' ' + decorClass) : '')"
            v-if="decorUrl != null && decorUrl !== ''"
            :src="decorUrl">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        props: {
            millingUrl: {
                type: String,
                required: false,
                default: null
            },
            decorUrl: {
                type: String,
                required: true
            },
            decorClass: {
                type: String,
            }
        }
    }
</script>
