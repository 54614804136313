<template>
    <b-card>
        <b-button class="mb-3" @click="openCreationDialog()">Добавить</b-button>
        <div v-for="tagGroup in tagGroups" class="mb-3" v-bind:key="tagGroup.id">
            <div class="d-flex mb-3 align-items-center">
                <h5><strong>{{tagGroup.name}}</strong></h5>
                <div class="ml-auto">
                    <b-button @click="openSubgroupCreationDialog(tagGroup)" class="m-1" title="Добавить подгруппу"><b-icon icon="plus" /></b-button>
                </div>
            </div>
            <template v-for="(tagSubgroup, index) in tagGroup.tagSubgroups"
            >
                <div class="d-flex pl-2 mb-2" v-bind:key="tagSubgroup.id * 2">
                    <strong>{{tagSubgroup.name}}</strong>
                    <div class="ml-auto" v-if="tagSubgroup.name !== 'Общее'">
                        <b-button @click="openSubgroupEditDialog(tagGroup, tagSubgroup)" class="m-1" title="Редактировать имя подгруппы"><b-icon icon="pencil" /></b-button>
                    </div>
                </div>
                <div v-for="tag in tagSubgroup.tags" v-bind:key="0xFFFF + tag.id * 0x143" class="d-flex pl-3 align-items-center">
                    <span>{{tag.name}}</span>
                    <div class="ml-auto">
                        <b-button @click="openEdit(tag, tagSubgroup)" class="m-1" title="Редактировать"><b-icon icon="pencil" /></b-button>
                        <b-button class="m-1" title="Удалить" @click="deleteTag(tag)"><b-icon icon="trash" /></b-button>
                    </div>
                </div>
                <hr v-if="tagGroup.tagSubgroups.length > 1 && tagGroup.tagSubgroups.length !== (index + 1)" v-bind:key="tagSubgroup.id * 2 + 1"/>
            </template>
        </div>
        <b-modal size="xl" ref="tagModal" :title="createdTag.id == null ? 'Добавление тэга' : 'Редактирование тэга'"
            :hide-footer="true">
            <multiselect
                :class="{ 
                        'invalid': $v.createdTag.subgroup.$error, 
                        'mb-3': true 
                        }"
                v-if="createdTag.id == null" v-model="$v.createdTag.subgroup.$model"
                :options="tagGroups"
                :multiple="false"
                group-values="tagSubgroups"
                group-label="name"
                :group-select="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Подгруппа тэгов"
                label="name" track-by="id"
                select-label="Выбрать"
                deselect-label="Убрать"
                selectedLabel="Выбрано">
                <template slot="singleLabel" slot-scope="{ option }">{{option.tagGroup.name + ': ' + option.name}}</template>
                <span slot="noResult">Не удалось найти группу тэгов с данным именем</span>
                <span slot="noOptions">Список пуст</span>
            </multiselect>
            <multiselect
                :class="{ 
                        'invalid': $v.createdTag.subgroup.$error, 
                        'mb-3': true 
                        }"
                v-else
                v-model="$v.createdTag.subgroup.$model"
                :options="tagGroups.find(tg => tg.id === createdTag.subgroup.tagGroup.id).tagSubgroups"
                :multiple="false"
                :group-select="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Подгруппа тэгов"
                label="name" track-by="id"
                select-label="Выбрать"
                deselect-label="Убрать"
                selectedLabel="Выбрано">
                <span slot="noResult">Не удалось найти группу тэгов с данным именем</span>
                <span slot="noOptions">Список пуст</span>
            </multiselect>
            <b-form-input placeholder="Имя тэга" class="mb-3" v-model="$v.createdTag.name.$model" :state="$v.createdTag.$dirty ? !$v.createdTag.name.$error : null"></b-form-input>
            <b-form-invalid-feedback v-if="!$v.createdTag.name.unique">
                Имя тэга должно быть уникальным
            </b-form-invalid-feedback>
            <div class="d-flex">
                <b-button class="ml-auto mr-3" variant="secondary" @click="closeModal()">Отмена</b-button>
                <b-button @click="saveTag()" :disabled="$v.createdTag.$anyError">Сохранить</b-button>
            </div>
        </b-modal>
        <b-modal size="xl" ref="tagSubgroupModal" :title="createdTagSubgroup.id == null ? 'Добавление подгруппы тэгов' : 'Редактирование подгруппы тэгов'"
            :hide-footer="true">
            <b-form-input placeholder="Имя подгруппы тэга" class="mb-3" v-model="$v.createdTagSubgroup.name.$model" :state="$v.createdTagSubgroup.$dirty ? !$v.createdTagSubgroup.name.$error : null"></b-form-input>
            <b-form-invalid-feedback v-if="!$v.createdTagSubgroup.name.unique">
                Имя подгруппы тэга должно быть уникальным в группе
            </b-form-invalid-feedback>
            <div class="d-flex">
                <b-button class="ml-auto mr-3" variant="secondary" @click="closeSubgroupModal()">Отмена</b-button>
                <b-button @click="saveTagSubgroup()" :disabled="$v.createdTagSubgroup.$anyError">Сохранить</b-button>
            </div>
        </b-modal>
    </b-card>
</template>
<script>
    import { required } from 'vuelidate/lib/validators';
    import api from '@/api/backend-api';
    import Multiselect from 'vue-multiselect';
    import {mapMutations} from 'vuex';
    export default {
        components: {
            'multiselect': Multiselect
        },
        mounted() {
            api.getTagGroups()
               .then(response => {
                   let groups = response.data.content;
                   for (let tg of groups) {
                       if (tg.tagSubgroups == null) {
                           continue;
                       }
                       tg.tagSubgroups = tg.tagSubgroups.sort((tag1, tag2) => {
                           if (tag1 == null || tag1.name == null || 'Общее' === tag1.name) {
                               return -1;
                           }
                           else if (tag2 == null || tag2.name == null || 'Общее' === tag2.name) {
                               return 1;
                           }
                           return tag1?.name?.localeCompare(tag2?.name, ["en", "ru"]);
                       });
                       tg.tagSubgroups.forEach(tagSubgroup =>  {
                           if (tagSubgroup.tagGroup == null) {
                               tagSubgroup.tagGroup = {
                                   id: tg.id,
                                   name: tg.name
                               };
                           }
                           for (let tag of tagSubgroup.tags) {
                               if (tag.tagSubgroup == null) {
                                   tag.tagSubgroup = tagSubgroup;
                               }
                           }
                       });
                   }

                   this.tagGroups = groups;
               })
        },
        computed: {
        },
        data(){
            return {
                tagGroups: [],
                createdTag: {
                    id: null,
                    subgroup: null,
                    name: null
                },
                createdTagSubgroup: {
                    id: null,
                    group: null,
                    name: null
                }
            }
        },
        validations() {
            return {
                createdTag: {
                    subgroup: {
                        required
                    },
                    name: {
                        required,
                        unique: (value) => {
                            return value !== 'Общее' && this.tagGroups//игнорим дефолтную группу
                                       .filter(tg => tg.subGroups != null)
                                       .flatMap(tg => tg.subGroups)
                                       .filter(tSubg => tSubg.tags != null)
                                       .flatMap(tSubg => tSubg.tags)
                                       .every(tag => tag.id === this.createdTag.id || tag.name !== value);
                        }
                    }
                },
                createdTagSubgroup: {
                    group: {
                        required
                    },
                    name: {
                        required,
                        unique: (value) => {
                            if (value == null || this.createdTagSubgroup.group == null) {
                                return true;
                            }
                            return this.tagGroups
                                       .find(tg => tg.id === this.createdTagSubgroup.group.id)
                                       .tagSubgroups
                                       .every(tagSg => tagSg.id === this.createdTagSubgroup.id || tagSg.name !== value);
                        }
                    }
                }
            }
        },
        methods: {
            openCreationDialog() {
                this.createdTag.id = null;
                this.$v.createdTag.name.$model =  null;
                this.$v.createdTag.subgroup.$model = null;
                this.$refs['tagModal'].show();
            },
            openEdit(item, tagSubgroup) {
                this.createdTag.id = item.id;
                this.$v.createdTag.name.$model = item.name;
                this.$v.createdTag.subgroup.$model = tagSubgroup;
                this.$refs['tagModal'].show();
            },
            openSubgroupCreationDialog(group) {
                this.createdTagSubgroup.id = null;
                this.$v.createdTagSubgroup.name.$model =  null;
                this.$v.createdTagSubgroup.group.$model = group;
                this.$refs['tagSubgroupModal'].show();
            },
            openSubgroupEditDialog(group, subgroup) {
                this.createdTagSubgroup.id = subgroup.id;
                this.$v.createdTagSubgroup.name.$model = subgroup.name;
                this.$v.createdTagSubgroup.group.$model = group;
                this.$refs['tagSubgroupModal'].show();
            },
            closeModal() {
                this.$refs['tagModal'].hide();
            },
            closeSubgroupModal() {
                this.$refs['tagSubgroupModal'].hide();
            },
            deleteTag(tag) {
                this.$bvModal.msgBoxConfirm("Вы уверены что хотите удалить тэг?", {
                    okVariant: 'danger',
                    okTitle: 'Подтвердить',
                    cancelTitle: 'Отмена',
                    title: 'Подтвердите действие'
                })
                    .then(val => {
                        if (val) {
                            return api.deleteTag(tag.id)
                                      .then(() => {
                                          this.dropTagGroups();
                                          let subGroup = this.tagGroups.flatMap(tg => tg.tagSubgroups)
                                                             .find(gr => gr.id == tag.tagSubgroup.id);
                                          if (subGroup == null) {
                                              return;
                                          }
                                          subGroup.tags = subGroup.tags.filter(item => item.id !== tag.id);
                                      });
                        }
                        return Promise.resolve(true);
                    });
            },
            saveTag() {
                this.$v.createdTag.$touch();
                if (this.$v.createdTag.$anyError) {
                    if (!this.$v.createdTag.name.unique) {
                        this.$bvToast.toast('Имя тэга должно быть уникальным', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                    }
                    else {
                        this.$bvToast.toast('Одно или несколько полей не были заполнены', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                    }
                    return;
                }
                if (this.createdTag.id == null) {
                    api.saveNewTag(this.createdTag.subgroup.id, this.createdTag.name)
                       .then(resp => {
                           this.dropTagGroups();
                           let subgroup = this.tagGroups
                                              .filter(tg => tg.tagSubgroups != null)
                                              .flatMap(tg => tg.tagSubgroups)
                                              .find(tg => tg.id === resp.data.tagSubgroupId);
                           if (subgroup == null) {
                               return;
                           }
                           subgroup.tags.push({
                               id: resp.data.id,
                               name: resp.data.name,
                               tagSubgroup: {
                                   id: resp.data.tagSubgroupId
                               }
                           });
                           this.$refs['tagModal'].hide();
                       }, e => {
                           if (e.isAxiosError && e.response.data
                               && e.response.data.message && e.response.data.message.includes('constraint [tag_name_unique]')) {//TODO: вообще надо бы перезагрузить список тэгов для такого случая
                               this.$bvToast.toast('Имя тэга должно быть уникальным', {
                                   title: 'Ошибка',
                                   autoHideDelay: 5000,
                                   appendToast: true,
                               });
                           }
                       });
                }
                else {
                    api.updateTag(this.createdTag.id, this.createdTag.subgroup.id, this.createdTag.name)
                       .then(resp => {
                           this.dropTagGroups();
                           let subgroup = this.tagGroups
                                              .filter(tg => tg.tagSubgroups != null)
                                              .flatMap(tg => tg.tagSubgroups)
                                              .find(tSupg => tSupg.id === resp.data.tagSubgroupId);
                           if (subgroup == null) {
                               return;
                           }
                           let tagData = subgroup.tags.find(tg => tg.id === resp.data.id);
                           if (tagData != null) {
                               tagData.name = resp.data.name;
                           }
                           else {
                               this.tagGroups
                                   .filter(tg => tg.tagSubgroups != null)
                                   .flatMap(tg => tg.tagSubgroups)
                                   .map(tsg => {
                                       return {i: tsg.tags.findIndex(t => t.id === resp.data.id), tsg};
                                   })
                                   .filter(({i}) => i >= 0)
                                   .forEach(({i, tsg}) => tsg.tags.splice(i, 1));
                               if (subgroup.tags == null) {
                                   subgroup.tags = [];
                               }
                               subgroup.tags.push({
                                   id: resp.data.id,
                                   name: resp.data.name,
                                   tagSubgroup: subgroup
                               });
                           }
                           this.$refs['tagModal'].hide();
                       }, e => {
                           if (e.isAxiosError && e.response.data
                               && e.response.data.message && e.response.data.message.includes('constraint [tag_name_unique]')) {//TODO: вообще надо бы перезагрузить список тэгов для такого случая
                               this.$bvToast.toast('Имя тэга должно быть уникальным', {
                                   title: 'Ошибка',
                                   autoHideDelay: 5000,
                                   appendToast: true,
                               });
                           }
                       });
                }
            },
            saveTagSubgroup() {
                this.$v.createdTagSubgroup.$touch();
                if (this.$v.createdTagSubgroup.$anyError) {
                    if (!this.$v.createdTagSubgroup.name.unique) {
                        this.$bvToast.toast('Имя подгруппы тэга должно быть уникальным', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                    }
                    else {
                        this.$bvToast.toast('Одно или несколько полей не были заполнены', {
                            title: 'Ошибка',
                            autoHideDelay: 5000,
                            appendToast: true,
                        });
                    }
                    return;
                }
                if (this.createdTagSubgroup.id == null) {
                    api.saveNewTagSubgroup(this.createdTagSubgroup.group.id, this.createdTagSubgroup.name)
                       .then(resp => {
                           this.dropTagGroups();
                           let group = this.tagGroups
                                           .find(tg => tg.id === resp.data.tagGroupId);
                           if (group == null) {
                               return;
                           }
                           if (group.tagSubgroups == null) {
                               group.tagSubgroups = [];
                           }
                           group.tagSubgroups.push({
                               id: resp.data.id,
                               name: resp.data.name,
                               tagGroup: group
                           });
                           this.$refs['tagSubgroupModal'].hide();
                       }, e => {
                           if (e.isAxiosError && e.response.data
                               && e.response.data.message && e.response.data.message.includes('constraint [tag_subgroup_name_unique]')) {//TODO: вообще надо бы перезагрузить список тэгов для такого случая
                               this.$bvToast.toast('Имя подгруппы тэга должно быть уникальным', {
                                   title: 'Ошибка',
                                   autoHideDelay: 5000,
                                   appendToast: true,
                               });
                           }
                       });
                }
                else {
                    api.updateTagSubgroup(this.createdTagSubgroup.id, this.createdTagSubgroup.group.id, this.createdTagSubgroup.name)
                       .then(resp => {
                           this.dropTagGroups();
                           let subgroup = this.tagGroups
                                              .find(tg => this.createdTagSubgroup.group.id == tg.id)
                                              .tagSubgroups
                                              .find(tSupg => tSupg.id === resp.data.id);
                           if (subgroup == null) {
                               return;
                           }
                           subgroup.name = resp.data.name;
                           this.$refs['tagSubgroupModal'].hide();
                       }, e => {
                           if (e.isAxiosError && e.response.data
                               && e.response.data.message && e.response.data.message.includes('constraint [tag_subgroup_name_unique]')) {//TODO: вообще надо бы перезагрузить список тэгов для такого случая
                               this.$bvToast.toast('Имя подгруппы тэга должно быть уникальным', {
                                   title: 'Ошибка',
                                   autoHideDelay: 5000,
                                   appendToast: true,
                               });
                           }
                       });
                }
            },
            ...mapMutations({
                dropTagGroups: 'START_TAG_GROUPS_LOADING'
            })
        }
    }
</script>
