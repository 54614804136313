<template>
    <b-card>
        <b-table fixed responsive id="combination-table" ref="combinationTable" striped show-empty
            :fields="fields"
            :items="getHandleItems"
        >
            <template #cell(title)="data">
                {{data.item.name}}
            </template>
            <template #cell(link)="data">
                <a :href="`/${docsPrefix}/${data.item.name}_ручки.pdf`" download>Скачать</a>
            </template>
        </b-table>
    </b-card>
</template>
<script>
    import api from '../api/backend-api';
    export default {
        data() {
            return {
                fields: [
                    {key: 'title', label: 'Название'},
                    {key: 'link', label: 'Ссылка на документ'}
                ]
            };
        },
        computed: {
            docsPrefix() {
                return api.docsPath;
            }
        },
        methods: {
            getHandleItems() {
                return api.getStyleData()
                          .then(response => {
                              let styles = response.data.content.sort(function (a, b) {
                                  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                              });
                              return styles;
                          });
            }
        }
    }
</script>
