import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api/backend-api';
 import auth from '@/api/backend-auth';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        userLoading: {
            loaded: false,
            loggedOut: false,
            loadPromise: false,
        },
        tagGroups: null,
        tagGroupsLoading: {
            loaded: false,
            loadPromise: null
        },
        styles: null,
        stylesLoading: {
            loaded: false,
            loadPromise: null
        },
        pageSizes: null,
        pageSizesLoading: {
            loaded: false,
            loadPromise: null
        },
        vendors: null,//TODO: сделать какую нибудь кодогенерацию
        vendorsLoading: {
            loaded: false,
            loadPromise: null
        },
        priceRanges: null,
        priceRangesLoading: {
            loadPromise: null,
            loaded: false,
        },
        roles: null,
        rolesLoading: {
            loadPromise: null,
            loaded: false,
        },
        
    },
    mutations: {
        UPDATE_USER(state, user) {
            state.user = user;
            state.userLoading = {
                loaded: true,
                loggedOut: false,
                loadPromise: null,
            };
        },
        USER_LOGOUT(state) {
            state.user = null;
            state.userLoading = {
                loaded: false,
                loggedOut: true,
                loadPromise: null
            };
        },
        USER_UPDATE_FILTER(state, filterForm) {
            if (state.user == null) {
                return;
            }
            state.user.filter = filterForm;
        },
        START_USER_LOADING(state, loadPromise) {
            state.userLoading.loadPromise = loadPromise;
            state.userLoading.loaded = false;
        },
        UPDATE_STYLES(state, styles) {
            styles = styles.sort(function (a, b) {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            });
            styles.forEach(style => {
                style.subStyles = style.subStyles.sort(function (a, b) {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                });
            });
            state.styles = styles;
            state.stylesLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        START_STYLES_LOADING(state, loadPromise) {
            state.stylesLoading = {
                loaded: false,
                loadPromise,
            };
        },
        UPDATE_TAG_GROUPS(state, tagGroups) {
            state.tagGroups = tagGroups;
            state.tagGroupsLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        START_TAG_GROUPS_LOADING(state, loadPromise) {
            state.tagGroupsLoading = {
                loaded: false,
                loadPromise,
            };
        },
        ADD_NEW_TAG(state, newTag) {
            let group = state.tagGroups.flatMap(tg => tg.tagSubgroups).find(tg => tg.id == newTag.tagSubgroupId);
            if (group == null) {
                console.error('invalid tag group id');
                return;
            }
            if (group.tags == null) {
                group.tags = [{
                    id: newTag.id,
                    name: newTag.name
                }];
            }
            else {
                group.tags.push({
                    id: newTag.id,
                    name: newTag.name
                });
            }
        },
        UPDATE_PAGE_SIZES(state, pageSizes) {
            state.pageSizes = pageSizes;
            state.pageSizesLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        START_PAGE_SIZES_LOADING(state, loadPromise) {
            state.pageSizesLoading = {
                loaded: false,
                loadPromise,
            };
        },
        UPDATE_VENDORS(state, vendors) {
            state.vendors = vendors;
            state.vendorsLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        START_VENDORS_LOADING(state, loadPromise) {
            state.vendorsLoading = {
                loaded: false,
                loadPromise,
            };
        },
        START_PRICE_RANGES_LOADING(state, loadPromise) {
            state.priceRangesLoading = {
                loaded: false,
                loadPromise,
            };
        },
        UPDATE_PRICE_RANGES(state, priceRanges) {
            state.priceRanges = priceRanges;
            state.priceRangesLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        ADD_NEW_PRICE_RANGE(state, priceRange) {
            state.priceRanges.push(priceRange);
        },
        UPDATE_ROLES(state, roles) {
            state.roles = roles;
            state.rolesLoading = {
                loaded: true,
                loadPromise: null,
            };
        },
        START_ROLES_LOADING(state, loadPromise) {
            state.rolesLoading = {
                loaded: false,
                loadPromise,
            };
        },
    },
    actions: {
        loadTagGroups({state, commit}) {
            let loadPromise;
            if (state.tagGroupsLoading.loaded) {
                return state.tagGroups;
            } else if (!state.tagGroupsLoading.loadPromise) {
                loadPromise = api.getTagGroups()
                                     .then(response => {
                                         commit('UPDATE_TAG_GROUPS', response.data.content);
                                     });
                commit('START_TAG_GROUPS_LOADING', loadPromise);
            } else {
                loadPromise = state.tagGroupsLoading.loadPromise;
            }
            return loadPromise
        },
        addNewTag({commit}, {tagSubgroupId, name}) {
            return api.saveNewTag(tagSubgroupId, name).
                then(resp => {
                    let newTag = resp.data;
                    commit('ADD_NEW_TAG', newTag);
                    return newTag;
                });
        },
        loadStyles({state, commit}) {
            let loadPromise;
            if (state.stylesLoading.loaded) {
                return state.styles;
            } else if (!state.stylesLoading.loadPromise) {
                loadPromise = api.getStyleData()
                                     .then(response => {
                                         commit('UPDATE_STYLES', response.data.content);
                                     });
                commit('START_STYLES_LOADING', loadPromise);
            } else {
                loadPromise = state.stylesLoading.loadPromise;
            }
            return loadPromise
        },
        loadPageSizes({state, commit}) {
            let loadPromise;
            if (state.pageSizesLoading.loaded) {
                return state.pageSizes;
            } else if (!state.pageSizesLoading.loadPromise) {
                loadPromise = api.getPageSizes()
                                     .then(response => {
                                         commit('UPDATE_PAGE_SIZES', response.data);
                                         return state.pageSizes;
                                     });
                commit('START_PAGE_SIZES_LOADING', loadPromise);
            } else {
                loadPromise = state.pageSizesLoading.loadPromise;
            }
            return loadPromise
        },
        loadVendors({state, commit}) {
            let loadPromise;
            if (state.vendorsLoading.loaded) {
                return state.vendors;
            } else if (!state.vendorsLoading.loadPromise) {
                loadPromise = api.getVendorData()
                                     .then(response => {
                                         commit('UPDATE_VENDORS', response.data.content);
                                     });
                commit('START_VENDORS_LOADING', loadPromise);
            } else {
                loadPromise = state.vendorsLoading.loadPromise;
            }
            return loadPromise
        },
        loadPriceRanges({state, commit}) {
            let loadPromise;
            if (state.priceRangesLoading.loaded) {
                return state.priceRanges;
            } else if (!state.priceRangesLoading.loadPromise) {
                loadPromise = api.getPriceRanges()
                                 .then(resp => {
                                     let priceRanges = resp.data
                                                           .filter(lst => lst.length == 2)
                                                           .map(lst => { return { left: lst[0], right: lst[1]}; });
                                     commit('UPDATE_PRICE_RANGES', priceRanges);
                                 });
                commit('START_PRICE_RANGES_LOADING', loadPromise);
            } else {
                loadPromise = state.priceRangesLoading.loadPromise;
            }
            return loadPromise
        },
        loadCurrentUser({state, commit}) {
            let loadPromise;
            if (state.userLoading.loaded) {
                return state.user;
            } else if (!state.userLoading.loggedOut && !state.userLoading.loadPromise) {
                loadPromise = auth.getCurrentUserInfo()
                                     .then(response => {
                                         commit('UPDATE_USER', response.data);
                                         return response.data;
                                     })
                                     .catch(e => {
                                         commit('USER_LOGOUT');
                                         throw e;
                                     });
                commit('START_USER_LOADING', loadPromise);
            } else {
                loadPromise = state.userLoading.loadPromise;
            }
            return loadPromise
            
        },
        performLogin({state, commit}, {login, password}) {
            if (state.userLoading.loadPromise) {
                return state.userLoading.loadPromise;
            }
            let loadPromise = auth.login(login, password)
                                  .then(auth.getCurrentUserInfo)
                                  .then((resp) => {
                                      commit('UPDATE_USER', resp.data);
                                      return resp.data;
                                  })
                                  .catch(e => {
                                      commit('USER_LOGOUT');
                                      throw e;
                                  });
            commit('START_USER_LOADING', loadPromise);
            return loadPromise;
        },
        updateUserFilter({commit}, filterForm) {
            return api.updateUserFilter(filterForm)
                      .then((resp) => {
                          commit('USER_UPDATE_FILTER', filterForm);
                          return resp;
                      });
        },
        loadRoles({state, commit}) {
            let loadPromise;
            if (state.rolesLoading.loaded) {
                return state.roles;
            } else if (!state.rolesLoading.loadPromise) {
                loadPromise = api.getRoles()
                    .then(response => {
                        commit('UPDATE_ROLES', response.data);
                        return state.roles;
                    });
                commit('START_ROLES_LOADING', loadPromise);
            } else {
                loadPromise = state.rolesLoading.loadPromise;
            }
            return loadPromise
        },
    },
    modules: {
    }
})
