<template>
  <b-card :title="'Создать пользователя'">
    <b-form v-if="contentLoaded" :label="`${outLabel}`" @submit.stop.prevent="onSubmit">
      <b-row>
        <b-form-group label="Логин" class="col">
          <b-form-input v-model="$v.login.$model"
                        :state="$v.login.$dirty ? !$v.login.$error : null"
                        placeholder="Логин"/>
          <p v-if="$v.login.$error "
             class="fieldError">Некорректное значение поля 'Логин' </p>
        </b-form-group>
        <b-form-group label="Пароль" class="col">
          <b-form-input type="password" v-model="$v.password.$model"
                        :state="$v.password.$dirty ? $v.password.isValidate && !$v.password.$error : null"
                        placeholder="Пароль"/>
          <p v-if="$v.password.$error "
             class="fieldError">Пароль должен иметь не менее 9 символов, прописные, заглавные буквы и цифры </p>
        </b-form-group>
        <b-form-group label="Повторите пароль" class="col">
          <b-form-input type="password" v-model="$v.passwordRepeat.$model"
                        :state="$v.passwordRepeat.$dirty ? $v.passwordRepeat.sameAsPassword : null"
                        placeholder="Повторите пароль"/>
          <p v-if="$v.passwordRepeat.$error "
             class="fieldError">Пароли не совпадают </p>
        </b-form-group>
      </b-row>
      <b-row>
        <b-form-group label="Фамилия" class="col">
          <b-form-input v-model="surname" placeholder="Фамилия"/>
        </b-form-group>
        <b-form-group label="Имя" class="col">
          <b-form-input v-model="firstName" placeholder="Имя"/>
        </b-form-group>
      </b-row>
      <b-row>
        <b-form-group label="Отчество" class="col">
          <b-form-input v-model="patronymic" placeholder="Отчество"/>
        </b-form-group>
        <b-form-group label="Роль" class="col">
          <multiselect
              v-model="$v.role.$model"
              placeholder="Выберите роль"
              :options="userRoles.map(u=>{return u.title})"
              :class="{ 'invalid': $v.role.$error }"
          />
          <p v-if="$v.role.$error "
             class="fieldError">Некорректное значение поля 'Роль' </p>
        </b-form-group>
      </b-row>
      <b-button class="mt-1 mb-3" :disabled="submitting || $v.$anyError || $v.passwordRepeat.$anyError"
                type="submit">Сохранить
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapState} from "vuex";
import {required, sameAs} from "vuelidate/lib/validators";
import api from "@/api/backend-api";

export default {
  name: "CreateUserComponent",
  props: {},
  components: {
    'multiselect': Multiselect,
  },
  data() {
    return {
      login: null,
      password: "",
      firstName: null,
      surname: null,
      patronymic: null,
      role: null,
      passwordRepeat: "",
      outLabel: "",
      submitting: false
    }
  },
  created() {
    this.loadRoles();
    this.loaded = true;
  },
  validations() {
    return {
      login: {
        required
      },
      password: {
        required,
        isValidate(value) {
          return value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{9,}$") != null;
        }
      },
      role: {
        required
      },
      passwordRepeat: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  computed: {
    ...mapState({
      userRoles: 'roles',
      contentLoaded: state => state.rolesLoading.loaded,
    }),
  },
  methods: {
    checkRole(value) {
      return value.title === this.role
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => {
          let element = this.$el.querySelector('.multiselect.invalid, .is-invalid, .card.invalid');
          let domRect = element.getBoundingClientRect();
          document.body.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
          );
        });
        this.$bvToast.toast('Одно или несколько полей не были заполнены', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.outLabel = 'Одно или несколько полей не были заполнены';
        return;
      }
      this.submitting = true;
      let user = {
        login: this.login,
        firstName: this.firstName,
        surname: this.surname,
        patronymic: this.patronymic,
        role: this.userRoles.find(this.checkRole).name,
      };
      api.createUser(user, this.password)
          .then(() => {
            this.$root.$bvToast.toast('Пользователь был успешно сохранен ', {
              title: 'Сохранение',
              autoHideDelay: 5000,
              appendToast: true,
            })
            if (window.history != null && window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push('/users');
            }
          })
          .catch(() => {
            this.$bvToast.toast('Произошла ошибка при сохранении пользователя', {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true,
            })
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    ...mapActions(['loadRoles']),
  },
}
</script>

<style scoped>

</style>