import {AXIOS_CONTENT as AXIOS} from './backend-config';

export default {
    getModel(style) {
        return AXIOS.get(`/models/${style}.json`);
    },
    get modelsPath() {
        return 'models';
    },
    get docsPath() {
        return 'docs';
    },
    get stubImagePath() {
        return 'images/!no.jpg';
    },
    get imagesPath() {
        //TODO: вытащить всё это в какой нибудь файл конфига фронтэнда
        return 'images';
    }
}
