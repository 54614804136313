<template>
  <div>
    <b-container fluid>
      <CombinationTable :page="parseInt(page)" :per-page="parseInt(perPage)"></CombinationTable>
    </b-container>
  </div>
</template>

<script>
    import CombinationTable from "@/components/CombinationTable";

    export default {
        name: 'Home',
        components: {
            CombinationTable
        },
        props: {
            page: {
                required: false,
                default: "1",
                validator: (prop) => typeof prop === 'string' || typeof prop === 'number'
            },
            perPage: {
                required: false,
                default: "15",
                validator: (prop) => typeof prop === 'string' || typeof prop === 'number'
            }
        }
    }
</script>
<style scoped>
</style>
