<template>
  <b-container>
    <CreateUserComponent/>
  </b-container>
</template>

<script>
import CreateUserComponent from "@/components/CreateUserComponent";

export default {
  name: "CreateUserPage",
  components: {CreateUserComponent}
}
</script>

<style scoped>

</style>