<template>
  <b-card :title="'Ошибка сервиса. Произолша ошибка соединения с сервером, пожалуйста, повторите позже'">
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-button v-text="`Вернуться на сайт`" :disabled="submitting" type="submit"></b-button>
      </b-form-group>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: "BackendErrorComponent",
  data() {
    return {
      submitting: false,
    }
  },
  methods: {
    onSubmit() {
      if (window.history != null && window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
  }
}
</script>

<style scoped>

</style>