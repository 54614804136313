<template>
    <div id="app">
        <kexpr-header/>
        <router-view v-if="userLoadingHandled" />
    </div>
</template>
<script>
    import { mapActions, mapState } from 'vuex'
    import Header from "@/components/Header";
    export default {
        components: {'kexpr-header': Header},
        created() {
            this.loadCurrentUser();
        },
        computed: {
            ...mapState({
                userLoadingHandled: state => state.userLoading.loaded || state.userLoading.loggedOut
            })
        },
        methods: {
            ...mapActions(['loadCurrentUser'])
        }
    }
</script>
