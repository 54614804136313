import axios from 'axios'
import store from '@/store';
import router from '@/router';

var AXIOS = axios.create({
    baseURL: '/kitchen-designer/',
    timeout: 300000,
    withCredentials: true,
    headers: {}
});

AXIOS.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
AXIOS.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;
    const method = error.config.method;
    if (status == 401 || status == 403) {
        if (store.state.user !== null) {
            router.push('/');
        } else {
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }
        }
    }//TODO: добавить запись в store
    if ((status == 500 || status == 504) && method == 'get') {
        if (router.currentRoute.path !== '/error') {
            router.push('/error');
        }
    }
    return Promise.reject(error);
});

var AXIOS_CONTENT = axios.create({
    baseURL: '/',
    timeout: 300000,
    withCredentials: true,
    headers: {}
});
AXIOS_CONTENT.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;

    if (status == 401 || status == 403) {
        if (store.state.user !== null) {
            router.push('/');
        } else {
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }
        }
    }//TODO: добавить запись в store
    return Promise.reject(error);
});
AXIOS_CONTENT.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export  {AXIOS, AXIOS_CONTENT};
