<template>
  <b-card :title="'Смена пароля'">
    <b-form @submit.stop.prevent="onSubmit"
            :title="`Пользователь: ${login}`">
      <b-form-group :label="`Пользователь: ${login}`">
        <b-row>
          <b-form-group class="col">
            <b-form-input type="password" v-model="$v.password.$model"
                          :state="$v.password.$dirty ? $v.password.isValidate && !$v.password.$error : null"
                          placeholder="Пароль"/>
            <p v-if="$v.password.$error "
               class="fieldError">Пароль должен иметь не менее 9 символов, прописные, заглавные буквы и цифры </p>
          </b-form-group>
          <b-form-group class="col">
            <b-form-input type="password" v-model="$v.passwordRepeat.$model"
                          :state="$v.passwordRepeat.$dirty ? $v.passwordRepeat.sameAsPassword : null"
                          placeholder="Повторите пароль"/>
            <p v-if="$v.passwordRepeat.$error "
               class="fieldError">Пароли не совпадают </p>
          </b-form-group>
        </b-row>
        <b-button class="mt-1 mb-3" :disabled="submitting || $v.$anyError || $v.passwordRepeat.$anyError"
                  type="submit">Сохранить
        </b-button>
      </b-form-group>
    </b-form>
  </b-card>
</template>

<script>
import api from "@/api/backend-api";
import {required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "ChangePasswordComponent",
  props: {
    login: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      password: null,
      submitting: false,
      passwordRepeat: null,
    }
  },
  validations() {
    return {
      password: {
        required,
        isValidate(value) {
          return value.match("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{9,}$") != null;
        }
      },
      passwordRepeat: {
        sameAsPassword: sameAs('password')
      }
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => {
          let element = this.$el.querySelector('.multiselect.invalid, .is-invalid, .card.invalid');
          let domRect = element.getBoundingClientRect();
          document.body.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
          );
        });
        this.$bvToast.toast('Одно или несколько полей не были заполнены', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.outLabel = 'Одно или несколько полей не были заполнены';
        return;
      }
      this.submitting = true;
      api.updateUserPassword(this.login, this.password)
          .then(() => {
            this.$root.$bvToast.toast('Пароль был успешно сохранен', {
              title: 'Сохранение',
              autoHideDelay: 5000,
              appendToast: true,
            })
            if (window.history != null && window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push('/users');
            }
          })
          .catch(() => {
            this.$bvToast.toast('Произошла ошибка при сохранении пароля', {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true,
            })
          })
          .finally(() => {
            this.submitting = false;
          });
    },
  }
}
</script>

<style scoped>

</style>