<template>
    <span :class="availabilityClass" v-if="!!material.name">{{prefix + material.name}}</span>
</template>
<script>
    export default {
        props: {
            material: {
                type: Object,
                required: true
            },
            prefix: {
                type: String,
                default: ''
            }
        },
        computed: {
            availabilityClass() {
                return {
                    'material-stop-sales': this.material.availability === 'STOP_SALES',
                    'material-unavailable': this.material.availability === 'UNAVAILABLE',
                    'material-available': this.material.availability === 'AVAILABLE'
                };
            }
        }
    }
</script>
