<template>
  <b-card :title="'Редактировать пользователя'">
    <b-form v-if="contentLoaded" :title="`Пользователь: ${login}`" @submit.stop.prevent="onSubmit">
      <b-form-group :label="`Пользователь: ${login}`">
        <b-row>
          <b-form-group label="Фамилия" class="col">
            <b-form-input v-model="user.surname" placeholder="Фамилия"/>
          </b-form-group>
          <b-form-group label="Имя" class="col">
            <b-form-input v-model="user.firstName" placeholder="Имя"/>
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Отчество" class="col">
            <b-form-input v-model="user.patronymic" placeholder="Отчество"/>
          </b-form-group>
          <b-form-group label="Роль" class="col">
            <multiselect
                v-model="$v.user.role.$model"
                placeholder="Выберите роль"
                :options="userRoles.map(u=>{return u.title})"
                :class="{ 'invalid': $v.user.role.$error }"
            />
            <p v-if="$v.user.role.$error "
               class="fieldError">Некорректное значение поля 'Роль' </p>
          </b-form-group>
        </b-row>
        <b-button v-text="`Сохранить изменения`" :disabled="submitting || $v.$anyError" type="submit"></b-button>
      </b-form-group>
    </b-form>
  </b-card>
</template>

<script>
import api from "@/api/backend-api";
import {required} from 'vuelidate/lib/validators';
import {mapActions, mapState} from 'vuex';
import Multiselect from "vue-multiselect";

export default {
  name: "EditUserComponent",
  props: {
    login: {
      type: String,
      required: true
    }
  },
  components: {
    'multiselect': Multiselect,
  },
  data() {
    return {
      user: {
        login: null,
        firstName: null,
        surname: null,
        patronymic: null,
        role: null,
      },
      submitting: false,
    }
  },
  validations() {
    let userValidator = {
      login: {
        required
      },
      role: {
        required
      }
    }
    return {
      user: userValidator,
    }
  },
  created() {
    api.getOneUser(this.login).then(response => {
      this.user = response.data;
      this.user.role = this.user.role.title;
      this.loaded = true;
    });
    this.loadRoles();
  },
  computed: {
    userRoles() {
      return this.userRoles;
    },
    ...mapState({
      userRoles: 'roles',
      contentLoaded: state => state.userLoading.loaded && state.rolesLoading.loaded,
    }),
  },
  methods: {
    checkRole(value) {
      return value.title === this.user.role
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        this.$nextTick(() => {
          let element = this.$el.querySelector('.multiselect.invalid, .is-invalid, .card.invalid');
          let domRect = element.getBoundingClientRect();
          document.body.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
          );
        });
        this.$bvToast.toast('Одно или несколько полей не были заполнены', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.submitting = true;
      let user = {
        login: this.user.login,
        firstName: this.user.firstName,
        surname: this.user.surname,
        patronymic: this.user.patronymic,
        role: this.userRoles.find(this.checkRole).name,
      };
      api.updateUser(user)
          .then(() => {
            this.$root.$bvToast.toast('Пользователь был успешно изменен', {
              title: 'Сохранение',
              autoHideDelay: 5000,
              appendToast: true,
            })
            if (window.history != null && window.history.length > 1) {
              this.$router.go(-1);
            } else {
              this.$router.push('/users');
            }
          })
          .catch(() => {
            this.$bvToast.toast('Произошла ошибка при изменении пользователя', {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true,
            })
          })
          .finally(() => {
            this.submitting = false;
          });
    },
    ...mapActions(['loadRoles']),
  }
}
</script>

<style scoped>

</style>