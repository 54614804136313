<template>
    <div class="d-block position-relative preview-block">
        <template v-if="mainImage">
            <img class="main-img" v-if="value.mainComp" ref="mainImageContainer" :style="mapMainStyle(value.mainComp)" :src="value.mainComp.image"/>
            <img class="position-absolute" v-for="(circ, index) in circuits" :key="index" :src="circ.image" :style="circ.style" />
            <div class="textures-block" :style="textureBlockStyle">
                <template v-for="(texture, index) in textures">
                    <div v-if="texture.image" class="position-absolute" :key="index" :style="texture.style" />
                </template>
            </div>
        </template>
    </div>
</template>
<style scoped>
    .preview-block {
    }
    .textures-block {
        top: 0;
        position: absolute;
        perspective-origin: center;
        perspective: 1000px;
        width: 100%;
        height: 100%;
    }
    .main-img {
        width: 100%;
        height: auto;
        position: relative;
    }
</style>
<script>
    export default {
        props: {
            value: {
                type: Object,
                required: true
            },
            replaceTextures: {
                type: Boolean,
                default: true
            },
            materials: {
                type: Object,
                default: null
            },
            circuitsHidden: {
                type: Boolean,
                default: false,
            }
        },
        mounted() {
            this.initializeImages();
        },
        computed: {
            circuits() {
                return this.value.circuits.map(this.mapCircuit)
            },
            textures() {
                return this.value.textures.map(this.mapTexture);
            }
        },
        watch: {
            value: function() {
                this.initializeImages();
            }
        },
        data() {
            return {
                mainImage: null,
                textureImages: null,
                textureBlockStyle: {},
            };
        },
        methods: {
            initializeImages() {
                this.mainImage = null;
                let mainImageObj = new Image();
                mainImageObj.onload = () => {
                    this.mainImage = mainImageObj;
                    this.$nextTick(() => { // иначе элемент не будет виден
                        if (this.$refs.mainImageContainer) {
                            this.textureBlockStyle = {
                                perspective: `${1000 * this.$refs.mainImageContainer.offsetWidth / this.mainImage.width }px`,
                            };
                        }
                        else {
                            this.textureBlockStyle = {};
                        }
                    })
                };
                mainImageObj.src = this.value.mainComp.image;
                this.textureImages = Object.fromEntries(Object.keys(this.materials).map(k => [k, null]));
                Object.entries(this.materials).forEach(data => {
                    let textureImg = new Image();
                    textureImg.onload = () => {
                        this.$nextTick(() => {
                            this.textureImages[data[0]] = textureImg;
                        });
                    };
                    textureImg.src = data[1];
                });
            },
            mapMainStyle(comp) {
                return {
                    position: comp.position,
                    left: comp.left? comp.left : 0,
                    top: comp.top ? comp.top : 0,
                    opacity: comp.opacity,
                    clipPath: comp.clipPath,
                    transform: comp.transform,
                    zIndex: comp.zIndex
                }
            },
            mapTexture(texture) {
                let image = this.replaceTextures ? this.materials[texture.className] : texture.image;
                if(!image){
                    if(!this.circuitsHidden){
                        image = this.materials[texture.classNameOptional];  //Когда опциональный контур виден
                    } else {
                        image = this.materials[texture.classNameSecond];    //Когда опционального контура нет
                    }
                }
                let textureImg = this.textureImages[texture.className];
                let backgroundSize = texture.backgroundSize;
                if (textureImg) {
                    if ((textureImg.width / textureImg.height) > 2) {
                        backgroundSize = '100%';
                    }
                    else {
                        backgroundSize = null;
                    }
                }
                return {
                    image: image,
                    style: {
                        backgroundImage: `url('${image}')`,
                        backgroundSize,
                        position: texture.position,
                        width: texture.width,
                        height: texture.height,
                        left: texture.left ? texture.left : 0,
                        top: texture.top ? texture.top : 0,
                        opacity: texture.opacity,
                        clipPath: texture.clipPath,
                        transform: texture.transform,
                        zIndex: texture.zIndex
                    }
                }
            },
            mapCircuit(circuit) {
                let visible = circuit.optional != null ? circuit.optional && !this.circuitsHidden : true;
                return {
                    image: circuit.image,
                    style: {
                        position: circuit.position,
                        width: circuit.width,
                        height: circuit.height,
                        left: circuit.left ? circuit.left : 0,
                        top: circuit.top ? circuit.top : 0,
                        opacity: circuit.opacity,
                        clipPath: circuit.clipPath,
                        transform: circuit.transform,
                        zIndex: circuit.zIndex,
                        visibility: visible ? 'visible' : 'hidden'
                    }
                }
            }
        }
    }
</script>
