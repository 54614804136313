<template>
    <b-row class="p-2" cols="1" cols-sm="2" cols-md="3" cols-lg="5">
        <b-card class="col pt-2" v-for="item in itemData"
            v-bind:key="item.id"
            no-body
            v-on:click="$emit('card-clicked', item)"
        >
            <div class="card-img-container">
                <b-card-img :src="resolveProp(item, imageSource, item.preview)"/>
                <slot name="image-overlay" v-bind:item="item"></slot>
            </div>
            <b-card-body>
                <h6 class="text-center">{{resolveProp(item, cardTitle, item.title)}}</h6>
            </b-card-body>
        </b-card>
    </b-row>
</template>
<script>
    export default {
        props: {
            currentPage: {
                type: Number,
                required: false,
                default: 1
            },
            perPage: {
                type: Number,
                required: false,
                default: 20
            },
            items: {
                required: false
            },
            imageSource: {
                required: false,
                default : null
            },
            cardTitle: {
                required: false,
                default : null
            }
        },
        watch: {
            items: function(){
                this.refresh();
            },
            currentPage: function(){
                this.refresh();
            },
            perPage: function(){
                this.refresh();
            }
        },
        data() {
            return {
                itemData: []
            };
        },
        mounted(){
            this.refresh();
        },
        methods: {
            refresh(){
                if (Array.isArray(this.items)){
                    this.itemData = this.items.slice((this.currentPage - 1) * this.perPage, (this.currentPage - 1) * this.perPage);
                }
                else if (typeof this.items === 'function'){
                    let result = this.items({
                        currentPage: this.currentPage,
                        perPage: this.perPage
                    },
                                            (data) => {
                                                if (data != null && Array.isArray(data)){
                                                    this.itemData = data;
                                                }
                                            });
                    if (typeof result !== 'object'){
                        //TODO: log
                        return;
                    }
                    if (Array.isArray(result)){
                        this.itemData = result;
                    }
                    else if (Object.getPrototypeOf(result) === Promise.prototype){
                        result.then(data => {
                            if (data != null && Array.isArray(data)){
                                this.itemData = data;
                            }
                        });
                    }
                    //TODO: log as well
                    
                }
            },
            resolveProp(item, prop, defaultValue){
                if (prop == null) {
                    return defaultValue;
                }
                if (typeof prop === 'string'){
                    return item[prop];
                }
                else if (typeof prop === 'function'){
                    return prop(item);
                }
                return defaultValue;
            }
        }
    }
</script>
<style scoped>
    .card-img-container {
        width: 100%;
        padding-top: 100%; /* 1:1 Aspect Ratio */
        position: relative;
    }

    .card-img-container >>> .card-img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
</style>
