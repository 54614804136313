<template>
    <div>
        <b-row align-h="end" :class="showFilter ? 'mb-3' : 'mb-0'">
            <b-col v-if="showCreateButton" class="mw-20">
                <b-button to="./create" block>
                    Новая запись
                </b-button>
            </b-col>
            <b-col v-if="showCopyButton" class="mw-20">
                <b-dropdown block split text="Копировать"  @click="(e) => $emit('copy-clicked', e, 1)" :disabled="copyButtonDisabled">
                    <b-dropdown-item @click="(e) => $emit('copy-clicked', e, 1)">Создать 1 копию</b-dropdown-item>
                    <b-dropdown-item @click="(e) => $emit('copy-clicked', e, 2)">Создать 2 копии</b-dropdown-item>
                    <b-dropdown-item @click="(e) => $emit('copy-clicked', e, 5)">Создать 5 копий</b-dropdown-item>
                    <b-dropdown-item @click="(e) => $emit('copy-clicked', e, 10)">Создать 10 копий</b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col v-if="showDeleteButton" class="mw-20">
                <b-button block  @click="(e) => $emit('delete-clicked', e)" :disabled="deleteButtonDisabled">
                    Удалить
                </b-button>
            </b-col>
            <b-col v-if="showEditButton" class="mw-20">
                <b-dropdown block split text="Замена материала"
                            @click="(e) => $emit('replacement-clicked', e)"
                            :disabled="materialReplacementButtonDisabled">
                    <b-dropdown-item href="#" @click="priceChangeState = !priceChangeState">Изменение цен
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col v-if="showFilter" class="mw-20">
                <b-button block @click="filterState = !filterState">
                    Фильтр
                </b-button>
            </b-col>
            <b-col v-if="!showFilter" class="mw-20 text-right">
                <b-link href="#" @click="filterState = !filterState">
                    <template v-if="filterState">Скрыть</template>
                    <template v-else>Показать</template>
                </b-link>
            </b-col>
        </b-row>
        <b-collapse :visible="filterState">
            <!-- костыль: почему то если пытаться все аргументы разворачивать, фреймворк генерит кривой код -->
            <ToolbarFilters @apply-filter="(a,b,c) => $emit('apply-filter', a, b, c)"
                            @update-kitchen-counter="count => kitchenCount = count"
                            :show-material-filters="showMaterialFilters"/>
        </b-collapse>
        <b-collapse :visible="priceChangeState">
            <hr/>
            <b-row class="align-items-end">
                <b-col class="mw-20">
                    <label for="price-multiplier">Коэффициент умножения</label>
                    <b-input id="price-multiplier" number v-model="priceMultiplier" placeholder="1.00" min="0"
                             type="number" step="0.05"></b-input>
                </b-col>
                <b-col class="mw-20">
                    <b-button :disabled="priceChangeButtonDisabled" block
                              @click="() => $emit('on-price-multiplier-changed', priceMultiplier)">Изменить
                    </b-button>
                </b-col>
                <b-col>
                    <b-checkbox @change="e => $emit('price-multiplier-all-selection-changed', e)">
                        Применить для всех ({{kitchenCount}} сочетаний)
                    </b-checkbox>
                </b-col>
            </b-row>
        </b-collapse>
    </div>
</template>

<script>
    import ToolbarFilters from "./ToolbarFilters";

    export default {
        name: "Toolbar",
        components: {ToolbarFilters},
        props: {
            showCreateButton: {
                type: Boolean,
                default: true
            },
            showCopyButton: {
                type: Boolean,
                default: true
            },
            showDeleteButton: {
                type: Boolean,
                default: true
            },
            showEditButton: {
                type: Boolean,
                default: true
            },
            showFilter: {
                type: Boolean,
                default: true
            },
            showMaterialFilters: {
                type: Boolean,
                default: true
            },
            materialReplacementButtonDisabled: {
                type: Boolean,
                default: false
            },
            copyButtonDisabled: {
                type: Boolean,
                default: false
            },
            deleteButtonDisabled: {
                type: Boolean,
                default: false
            },
            showPriceChange: {
                type: Boolean,
                default: false
            },
            priceChangeButtonDisabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                filterState: !this.showFilter,
                priceChangeState: false,
                priceMultiplier: 1,
                kitchenCount: 0
            }
        }
    }
</script>

<style scoped>
    .row {
        margin-bottom: 15px;
    }
    .mw-20 {
        max-width: 20%;
    }
</style>
