<template>
  <b-card :title="'Управление пользователями'">
    <b-form>
      <b-button to="/users/create" class="mb-2">
        Добавить пользователя
      </b-button>
      <b-table bordered
               :items="getUsers"
               :fields="fields"
               :key="table"
               class="table">
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(actions)="data">
          <b-button size="sm" :to="`users/${data.item.login}`" class="mr-1">
            <b-icon-pencil></b-icon-pencil>
          </b-button>
          <b-button size="sm" :to="`/users/${data.item.login}/password`" class="mr-1">
            <b-icon-key></b-icon-key>
          </b-button>
          <b-button size="sm" @click="deleteUser(`${data.item.login}`)" class="mr-1">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </template>
      </b-table>
    </b-form>
  </b-card>
</template>

<script>
import api from '@/api/backend-api';

export default {
  name: "ShowUserComponent",
  data() {
    return {
      sortBy: 'login',
      sortDesc: false,
      users: null,
      table: 0,
      fields: [
        {key: 'index', label: '№'},
        {key: 'login', label: 'Логин'},
        {key: 'surname', label: 'Фамилия'},
        {key: 'firstName', label: 'Имя'},
        {key: 'patronymic', label: 'Отчество'},
        {key: 'role.title', label: 'Роль'},
        {key: 'actions', label: 'Действия'}
      ]
    }
  },
  created() {
    this.users = api.getAllUsers().then(response => {
      return response.data;
    });
  },
  methods: {
    getUsers() {
      return this.users;
    },
    deleteUser(login) {
      this.$bvModal.msgBoxConfirm("Вы уверены что хотите удалить пользователя?", {
        okVariant: 'danger',
        okTitle: 'Подтвердить',
        cancelTitle: 'Отмена',
        title: 'Подтвердите действие'
      }).then(val => {
        if (val) {
          api.deleteUser(login)
              .then(() => {
                this.$root.$bvToast.toast('Пользователь был успешно удалён', {
                  title: 'Сохранение',
                  autoHideDelay: 5000,
                  appendToast: true,
                })
                this.users = api.getAllUsers().then(response => {
                  return response.data;
                });
              })
              .catch(() => {
                this.$bvToast.toast('Произошла ошибка при удалении пользователя', {
                  title: 'Ошибка',
                  autoHideDelay: 5000,
                  appendToast: true,
                })
              })
              .finally(() => {
                this.submitting = false;
                this.table+=1;
              });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>