<template>
    <b-container>
        <kitchen-form class="mb-4"
            :creating="creating"
            :id="id" />
    </b-container>
</template>
<script>
    import KitchenForm from '@/components/KitchenForm';
    export default {
        name: 'EditPage',
        props: {
            creating: {
                type: Boolean,
                required: true
            },
            id: {
                type: Number,
                default: null
            }
        },
        components: {
            'kitchen-form': KitchenForm
        }
    }
</script>
