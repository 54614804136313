import {AXIOS} from "./backend-config";
import qs from 'qs';
//import path from 'path';
export default {
    get imagesPath() {
        //TODO: вытащить всё это в какой нибудь файл конфига фронтэнда
        return 'images';/*try {
            return new URL('images', AXIOS.defaults.baseURL).href;
        }
        catch (e) {
            return path.join(AXIOS.defaults.baseURL, 'images');
        }*/
    },
    get modelsPath() {
        return 'models';
    },
    get docsPath() {
        return 'docs';
    },
    get stubImagePath() {
        return 'images/!no.jpg';
    },
    getTableData(pageSize, pageNum, sortBy, sortDirection, filter) {
        return AXIOS.get("/api/kitchen/", {
            params: Object.assign({
                page: pageNum,
                size: pageSize,
                sort: sortBy + "," + (sortDirection ? "DESC" : "ASC"),
            }, this.mapFilter(filter)),
            paramsSerializer: params => qs.stringify(params, {allowDots: true, skipNulls: true, arrayFormat: "repeat"})
        });
    },
    getCounterData(filter) {
        return AXIOS.get('/api/kitchen/counter', {
            params: this.mapFilter(filter),
            paramsSerializer: params => qs.stringify(params, {allowDots: true, skipNulls: true, arrayFormat: "repeat"})
        });
    },
    getSingleKitchen(id) {
        return AXIOS.get(`/api/kitchen/${id}`, {});
    },
    deleteMultipleKitchens(ids) {
        return AXIOS.delete('/api/kitchen/', {
            data: ids
        });
    },
    cloneMultipleKitchens(ids, copyCount) {
        if (copyCount == null) {
            copyCount = 1;
        }
        return AXIOS.post('/api/kitchen/clone', {
            ids,
            copyCount
        });
    },
    mapKitchen(kitchen) {
        return {
            id: kitchen.id,
            cupboardHandle: kitchen.cupboardHandle?.id,
            facadeAccentDecor: kitchen.facadeAccentDecor?.id,
            facadeAccentMilling: kitchen.facadeAccentMilling?.id,
            facadeAccentPatina: kitchen.facadeAccentPatina?.id,
            facadeAccentSurface: kitchen.facadeAccentSurface?.id,
            facadeFirstDecor: kitchen.facadeFirstDecor?.id,
            facadeFirstMilling: kitchen.facadeFirstMilling?.id,
            facadeFirstPatina: kitchen.facadeFirstPatina?.id,
            facadeFirstSurface: kitchen.facadeFirstSurface?.id,
            facadeSecondDecor: kitchen.facadeSecondDecor?.id,
            facadeSecondMilling: kitchen.facadeSecondMilling?.id,
            facadeSecondPatina: kitchen.facadeSecondPatina?.id,
            facadeSecondSurface: kitchen.facadeSecondSurface?.id,
            kitchenStyle: kitchen.kitchenStyle?.id,
            kitchenSubStyle: kitchen.kitchenSubStyle?.id,
            metalProduct: kitchen.metalProduct?.id,
            tableDecor: kitchen.tableDecor?.id,
            tags: kitchen.tags?.map(tag => tag?.id),
            wallPanelDecor: kitchen.wallPanelDecor?.id,
            availability: kitchen.availability,
            pricePerMeter: (kitchen.pricePerMeter === "" || kitchen.pricePerMeter == null) ? null : Number(kitchen.pricePerMeter),
        }
    },
    saveKitchen(kitchen) {
        if (kitchen.id == null) {
            return AXIOS.post('/api/kitchen/', this.mapKitchen(kitchen));
        } else {
            return AXIOS.put(`/api/kitchen/${kitchen.id}`, this.mapKitchen(kitchen));
        }
    },
    getMaterialData(pageSize, pageNum, sortBy, sortDirection, filters) {
        return AXIOS.get("/api/kitchen_material/", {
            params: Object.assign({
                page: pageNum,
                size: pageSize,
                sort: sortBy != null ? (sortBy + "," + sortDirection) : null
            }, filters),
            paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
    },
    getStyleData(pageSize, pageNum, sortBy, sortDirection) {
        return AXIOS.get("/api/kitchen_style/", {
            params: {
                page: pageNum == null ? 0 : pageNum,
                size: pageSize == null ? 0x7FFFFFFF : pageSize,
                sort: sortBy != null ? (sortBy + "," + sortDirection) : null
            }
        })
    },
    getVendorData(pageSize, pageNum, sortBy, sortDirection, filters) {
        return AXIOS.get("/api/vendor/", {
            params: Object.assign({
                page: pageNum == null ? 0 : pageNum,
                size: pageSize == null ? 0x7FFFFFFF : pageSize,
                sort: sortBy != null ? (sortBy + "," + sortDirection) : null
            }, filters),
            paramsSerializer: params => qs.stringify(params, { allowDots: true })
        })
    },
    getTagGroups() {
        return AXIOS.get("/api/tag_group/");
    },
    deleteTag(id) {
	return AXIOS.delete(`/api/tag/${id}`);
    },
    updateTagSubgroup(id,tagGroupId, subgroupName) {
	return AXIOS.put(`/api/tag_subgroup/${id}`, {
            tagGroupId,
            name: subgroupName
	});
    },
    saveNewTagSubgroup(tagGroupId, subgroupName) {
        return AXIOS.post("/api/tag_subgroup/", {
            tagGroupId,
            name: subgroupName
        });
    },
    updateTag(id,tagSubgroupId,tagValue) {
	return AXIOS.put(`/api/tag/${id}`, {
            tagSubgroupId,
            name: tagValue
	});
    },
    saveNewTag(tagSubgroupId, tagValue) {
        return AXIOS.post("/api/tag/", {
            tagSubgroupId,
            name: tagValue
        });
    },
    getPriceRanges() {
        return AXIOS.get("/api/kitchen/priceRanges");
    },
    getPageSizes() {
        return AXIOS.get("/api/kitchen/pageSizes");
    },
    replaceMaterials(replacements, exclusions) {
        let replacementsRequest = replacements.map(r => this.mapMaterialReplacement(r));
        let exclusionsRequest = exclusions.map(r => this.mapMaterialExclusion(r));
        return AXIOS.post('/api/kitchen/replaceMaterials', {
            replacements: replacementsRequest,
            exclusions: exclusionsRequest
        });
    },
    mapMaterialExclusion(ex) {
        return {
            materialType: ex.materialField?.field,
            materialId: ex.material?.id,
        };
    },
    mapMaterialReplacement(replacement) {
        return {
            materialType: replacement.materialField?.field,
            oldMaterialId: replacement.oldMaterial?.id,
            newMaterialId: replacement.newMaterial?.id,
            millingMaterialId: replacement.millingMaterial?.id,
            decorMaterialId: replacement.decorMaterial?.id,
            surfaceMaterialId: replacement.surfaceMaterial?.id,
            patinaMaterialId: replacement.patinaMaterial?.id,
        };
    },
    mapFilter(filter) {
        return {
            kitchenStyle: filter?.kitchenStyle?.map(style => style.id),
            kitchenSubStyle: filter?.kitchenSubStyle?.map(subStyle => subStyle.id),
            tags: filter?.tags?.map(tag => tag.id),
            ignoreIds: filter?.ignoreIds,
            availability: filter?.availability,
            pricePerMeter: filter?.pricePerMeter?.map(p => [p.left, p.right]),
            includedMaterialName: ((s) => {
                if (s != null && s.length < 3) {
                    return null;
                }
                return s;
            })(filter?.includedMaterialName),
            facadeFirstDecor: {
                name: filter?.facadeFirstDecor?.name,
                vendorId: filter?.facadeFirstDecor?.vendor?.map(vendor => vendor.id)
            },
            facadeFirstMilling: {
                name: filter?.facadeFirstMilling?.name
            },
            facadeFirstPatina: {
                name: filter?.facadeFirstPatina?.name
            },
            facadeFirstSurface: {
                name: filter?.facadeFirstSurface?.name
            },
            facadeSecondDecor: {
                name: filter?.facadeSecondDecor?.name,
                vendorId: filter?.facadeSecondDecor?.vendor?.map(vendor => vendor.id)
            },
            facadeSecondMilling: {
                name: filter?.facadeSecondMilling?.name
            },
            facadeSecondPatina: {
                name: filter?.facadeSecondPatina?.name
            },
            facadeSecondSurface: {
                name: filter?.facadeSecondSurface?.name
            },
            facadeAccentDecor: {
                name: filter?.facadeAccentDecor?.name,
                vendorId: filter?.facadeAccentDecor?.vendor?.map(vendor => vendor.id)
            },
            facadeAccentMilling: {
                name: filter?.facadeAccentMilling?.name
            },
            facadeAccentPatina: {
                name: filter?.facadeAccentPatina?.name
            },
            facadeAccentSurface: {
                name: filter?.facadeAccentSurface?.name
            },
            tableDecor: {
                name: filter?.tableDecor?.name,
                vendorId: filter?.tableDecor?.vendor?.map(vendor => vendor.id)
            },
            wallPanelDecor: {
                name: filter?.wallPanelDecor?.name,
                vendorId: filter?.wallPanelDecor?.vendor?.map(vendor => vendor.id)
            },
            cupboardHandle: {
                name: filter?.cupboardHandle?.name,
                vendorId: filter?.cupboardHandle?.vendor?.map(vendor => vendor.id)
            },
            metalProduct: {
                name: filter?.metalProduct?.name,
                vendorId: filter?.metalProduct?.vendor?.map(vendor => vendor.id)
            }
        }
    },
    getUserFilter() {
        return AXIOS.get("/user/filter");
    },
    updateUserFilter(filter) {
        return AXIOS.post("/user/filter/update", filter);
    },
    getAllUsers() {
        return AXIOS.get("/user/all");
    },
    getOneUser(login) {
        return AXIOS.get(`/user/${login}`);
    },
    updateUser(user) {
        return AXIOS.post(`/user/update`, user);
    },
    updateUserPassword(login, password) {
        return AXIOS.post(`/user/password/${login}`, {password: password});
    },
    createUser(user, password) {
        return AXIOS.post("/user/create", {user: user, password: password});
    },
    deleteUser(login) {
        return AXIOS.post(`/user/delete/${login}`);
    },
    getRoles() {
        return AXIOS.get(`/user/roles`);
    },
    updatePricesById(selectedKitchens, priceMultiplier) {
        return AXIOS.post('/api/kitchen/updatePricesById', {
            priceMultiplier,
            ids: selectedKitchens?.map(kitchen => kitchen.id)
        });
    },
    updatePricesByFilter(filter, priceMultiplier) {
        return AXIOS.post('/api/kitchen/updatePricesByFilter', {
            priceMultiplier,
            ids: [],
            filter: this.mapFilter(filter)
        });
    }
}
