<template>
  <b-container>
    <BackendErrorComponent/>
  </b-container>
</template>

<script>
import BackendErrorComponent from "@/components/BackendErrorComponent";

export default {
  name: "BackendErrorPage",
  components: {BackendErrorComponent}
}
</script>

<style scoped>

</style>