<template>
    <div>
        <b-navbar type="light">
            <b-navbar-brand href="#">
                <img src="@/assets/logo.png">
            </b-navbar-brand>
            <b-navbar-nav v-if="user != null">
                <b-nav-item to="/" v-if="route !== '/' && route !== '/preview'">
                    Главная страница
                </b-nav-item>
                <b-nav-item to="/handles" target="_blank">Ручки</b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto" v-if="route !== '/preview'">
                <template v-if="user != null">
                    <b-nav-text class="px-2"><em>{{username}}</em></b-nav-text>
                    <b-nav-item-dropdown text="Настройки" v-if="isManager"
                        right>
                        <b-dropdown-item to="/tags" v-if="route !== '/tags'">
                            Управление тэгами
                        </b-dropdown-item>
                      <b-dropdown-item to="/users" v-if="route !== '/users'">
                        Управление пользователями
                      </b-dropdown-item>
                    </b-nav-item-dropdown>
                    <b-nav-item @click="logout" href="#">Выход</b-nav-item>
                </template>
                <b-nav-item v-else to="/login">
                    Вход
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
    import auth from '@/api/backend-auth';

    export default {
        name: "Header",
        computed: {
            user(){
                return this.$store.state.user;
            },
            username(){
                let user = this.user;
                if (user == null){
                    return null;
                }
                if (user.surname == null || user.firstName == null){
                    return user.login;
                }
                else if (user.patronymic != null){
                    return user.surname + ' ' + user.firstName + ' ' + user.patronymic;
                }
                else {
                    return user.surname + ' ' + user.firstName;
                }
            },
            isManager() {
                return this.user != null && this.user.role.name === 'MANAGER';
            },
            route() {
                return this.$route.path
            }
        },
        methods: {
            logout(){
                auth.logout()
                    .then(()=> {
                        this.$router.push('/login');
                    });
            }
        }
    }
</script>

<style scoped>
    .navbar {
        margin-bottom: 15px;
        background-color: #f3f3f3;
        border-bottom: 1px solid #dee2e6;
    }
    img {
        max-width: 10vw;
    }
</style>
