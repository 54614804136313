<template>
    <b-container fluid>
        <KitchenPreview :id="id" />
    </b-container>
</template>

<script>
    import KitchenPreview from "@/components/KitchenPreview";
    export default {
        name: "KitchenPreviewPage",
        components: {KitchenPreview},
        props: {
            id: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
