<template>
    <b-card :title="id ? 'Редактирование кухни' : 'Создание новой кухни'">
        <b-form v-if="kitchenData != null && contentLoaded" @submit.stop.prevent="onSubmit">
            <b-button class="mt-1 mb-3" :disabled="submitting || $v.kitchenData.$anyError" type="submit">Сохранить</b-button>
            <b-row>
                <b-form-group label="Стиль кухни" class="col">
                    <multiselect
                        v-model="$v.kitchenData.kitchenStyle.$model"
                        label="name"
                        :options="kitchenStyles"
                        placeholder="Выбрать стиль"
                        :show-labels="false"
                        :class="{ 'invalid': $v.kitchenData.kitchenStyle.$error }"
                    />
                </b-form-group>
                <b-form-group label="Подстиль кухни" class="col">
                    <multiselect
                        label="name"
                        :options="kitchenData.kitchenStyle == null ? [] : kitchenData.kitchenStyle.subStyles"
                        :disabled="kitchenData.kitchenStyle == null"
                        v-model="$v.kitchenData.kitchenSubStyle.$model"
                        placeholder="Выбрать подстиль"
                        :show-labels="false"
                        :class="{ 'invalid': $v.kitchenData.kitchenSubStyle.$dirty ? $v.kitchenData.kitchenSubStyle.$error : false }"
                    />
                </b-form-group>
            </b-row>
            <b-form-group label="Цена за погонный метр (в тыс. руб.)">
                <b-form-input v-model="$v.kitchenData.pricePerMeter.$model" placeholder="Цена" :state="$v.kitchenData.pricePerMeter.$dirty ? !$v.kitchenData.pricePerMeter.$error : null" />
            </b-form-group>
            <b-form-group label="Тэги" class="mb-3">
                <multiselect v-model="kitchenData.tags"
                    class="mb-2"
                    :options="tagGroups"
                    :multiple="true"
                    group-values="tags"
                    group-label="name"
                    :group-select="true"
                    :close-on-select="false"
                    track-by="id"
                    label="name"
                    deselect-label="Нажмите, чтобы убрать тэг"
                    select-label="Нажмите, чтобы добавить тэг"
                    placeholder="Нажмите для поиска тэгов"
                    select-group-label="Нажмите для выбора всех тэгов из группы"
                    deselect-group-label="Нажмите, чтобы убрать все тэги из данной группы"
                    selected-label="Тэг установлен"
                >
                    <span slot="noResult">Ни один тэг не был найден</span>
                </multiselect>
                <b-button @click="tagAdditionVisible = !tagAdditionVisible">
                    <template v-if="!tagAdditionVisible">Добавить тэг</template>
                    <template v-else>Скрыть</template>
                </b-button>
                <b-collapse v-model="tagAdditionVisible" class="mt-2">
                    <multiselect v-model="$v.tagAdditionData.tagSubgroup.$model"
                        label="name"
                        deselect-label="Нажмите, чтобы убрать группу тэгов"
                        select-label="Нажмите, чтобы выбрать группу тэгов"
                        :options="tagGroupsFull"
                        group-values="tagSubgroups"
                        group-label="name"
                        :group-select="true"
                        placeholder="Нажмите для поиска групп тэгов"
                        :show-labels="false"
                        :class="{ 'invalid': $v.tagAdditionData.tagSubgroup.$error }">
                        <span slot="noResult">Ни одна группа тэгов не была найдена</span>
                        <template slot="singleLabel" slot-scope="{ option }">{{option.tagGroupName + ': ' + option.name}}</template>
                    </multiselect>
                    <b-form-input class="mt-2"
                        v-model="$v.tagAdditionData.name.$model"
                        :state="$v.tagAdditionData.name.$dirty ? !$v.tagAdditionData.name.$error : null"
                    ></b-form-input>
                    <b-button type="button" class="mt-2" :disabled="$v.tagAdditionData.$anyError" @click="addTagValue">Добавить тэг</b-button>
                </b-collapse>
            </b-form-group>
            <template v-for="(matGroup, index) in $options.KITCHEN_MATERIAL_GROUPS">
                <h4 v-bind:key="index * 2" v-if="matGroup.title != null">{{matGroup.title}}</h4>
                <b-row v-bind:key="index * 2 + 1" class="mb-4">
                    <b-col v-for="(material, j) in matGroup.materials" v-bind:key="j" class="d-flex">
                        <material-card :title="$options.KITCHEN_MATERIAL_FIELDS[material].title"
                            :state="$v.kitchenData[material].$dirty ? !$v.kitchenData[material].$error : null"
                            :material="kitchenData[material]"
                            @replace-material="changeMaterial(material)"
                            @drop-material="dropMaterial(material)"
                        />
                    </b-col>
                </b-row>
            </template>
            <b-button :disabled="submitting || $v.kitchenData.$anyError" type="submit">Сохранить</b-button>
        </b-form>
        <b-modal size="xl" ref="materialPickerModal" title="Выбор материала"
            :hide-footer="true"
        >
            <multiselect
                v-model="selectedVendor"
                label="name"
                :options="materialVendorOptions"
                placeholder="Выбрать поставщика"
                :disabled="$options.KITCHEN_MATERIAL_FIELDS[kitchenMaterialPickerField] == null || (!$options.KITCHEN_MATERIAL_FIELDS[kitchenMaterialPickerField].canModifyVendor)"
                :show-labels="false"/>
            <b-form-input v-model="filterMaterialName" placeholder="Название материала" class="mt-2" @input="$refs.materialPicker.refresh()" />
            <kitchen-material-picker @card-clicked="onCardPicked"
                :currentPage="currentPage"
                :perPage="perPage"
                :items="loadMaterials"
                :imageSource="(item) => item.previewPath != null && item.previewPath.length > 0 ?  `${imageRemoteURL}/${item.previewPath[0]}` : stubImageRemoteURL"
                :cardTitle="(item) => item.name"
                ref="materialPicker">
                <template #image-overlay="data">
                    <b-badge :variant="materialStatusVariant(data.item.availability)" class="badge-overlay">{{materialStatusText(data.item.availability)}}</b-badge>
                </template>
            </kitchen-material-picker>
            <!-- TODO: добавить нормальную затычку если превьюхи нет -->
            <b-pagination v-model="currentPage" :total-rows="totalElements" align="right" size="lg"
                :per-page="perPage"></b-pagination>
        </b-modal>
    </b-card>
</template>
<script>
    import api from '@/api/backend-api';
    import KitchenMaterialPicker from './KitchenMaterialPicker';
    import MaterialCard from './material/MaterialCard';
    import Multiselect from 'vue-multiselect';
    import { required, minLength, minValue, integer } from 'vuelidate/lib/validators';
    import { mapActions, mapState } from 'vuex';

    const kitchenMaterialFields = {
        facadeFirstDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
            canModifyVendor: true,
            vendorAffects: ['facadeFirstMilling', 'facadeFirstSurface', 'facadeFirstPatina'],
            required: true
        },
        facadeFirstMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeFirstSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeFirstPatina: {
            title:'Патина',
            materialType: 'PATINA',
        },
        facadeSecondDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
            canModifyVendor: true,
            vendorAffects: ['facadeSecondMilling', 'facadeSecondSurface', 'facadeSecondPatina']
        },
        facadeSecondMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeSecondSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeSecondPatina: {
            title:'Патина',
            materialType: 'PATINA',
        },
        facadeAccentDecor: {
            title:'Декор',
            materialType: 'FACADE_DECORATION',
            canModifyVendor: true,
            vendorAffects: ['facadeAccentMilling', 'facadeAccentSurface', 'facadeAccentPatina']
        },
        facadeAccentMilling: {
            title:'Фрезеровка',
            materialType: 'MILLING',
        },
        facadeAccentSurface: {
            title:'Поверхность',
            materialType: 'SURFACE',
        },
        facadeAccentPatina:  {
            title: 'Патина',
            materialType: 'PATINA',
        },
        tableDecor: {
            title:'Столешница',
            materialType: 'TABLETOP_DECORATION',
            canModifyVendor: true,
            required: true
        },
        wallPanelDecor: {
            title:'Стеновая панель',
            materialType: 'WALL_DECORATION',
            canModifyVendor: true,
            required: true
        },
        cupboardHandle: {
            title:'Ручки',
            materialType: 'HANDLES',
            canModifyVendor: true,
        },
        metalProduct: {
            title:'Металлоизделия',
            materialType: 'METALWARE',
            canModifyVendor: true,
        },
    };

    const AVAILABILITY_STATUS = {
        UNAVAILABLE: {
            variant: 'danger',
            text: 'Снят'
        },
        STOP_SALES: {
            variant: 'warning',
            text: 'В СТОПе'
        },
        AVAILABLE: {
            variant: 'success',
            text: 'Доступно'
        },
    };
    
    export default {
        components: {
            'kitchen-material-picker': KitchenMaterialPicker,
            'multiselect': Multiselect,
            'material-card': MaterialCard,
        },
        KITCHEN_MATERIAL_FIELDS: kitchenMaterialFields,
        KITCHEN_MATERIAL_GROUPS: [
            {
                title: "Фасад 1",
                materials: ["facadeFirstDecor", "facadeFirstMilling", "facadeFirstSurface", "facadeFirstPatina"]
            },
            {
                title: "Фасад 2",
                materials: ["facadeSecondDecor", "facadeSecondMilling", "facadeSecondSurface", "facadeSecondPatina"]
            },
            {
                title: "Фасад-акцент",
                materials: ["facadeAccentDecor", "facadeAccentMilling", "facadeAccentSurface", "facadeAccentPatina"]
            },
            {
                title: null,
                materials: ["tableDecor", "wallPanelDecor", "cupboardHandle", "metalProduct"]
            }
        ],
        created() {
            if (this.id == null || isNaN(this.id) ) {
                // сборка объекта
                let kitchenDataTmp = Object.fromEntries( Object.keys(kitchenMaterialFields).map(f => [f,null]));
                this.kitchenData = Object.assign({
                    id: null,
                    kitchenStyle: null,
                    kitchenSubStyle: null,
                    pricePerMeter: null,
                    tags: []
                }, kitchenDataTmp);
                this.selectedVendors = Object.assign({}, kitchenDataTmp);
            }
            else {
                api.getSingleKitchen(this.id)
                   .then(resp => {
                       let fields = Object.keys(kitchenMaterialFields);
                       let kitchenDataTmp = Object.fromEntries( fields.map(f => [f,null]));
                       if (resp.data.kitchenStyle != null && resp.data.kitchenStyle.subStyles != null) {
                           resp.data.kitchenStyle.subStyles = resp.data.kitchenStyle.subStyles.sort(function (a, b) {
                               return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                           });
                       }
                       this.kitchenData = Object.assign({
                           id: null,
                           kitchenStyle: null,
                           kitchenSubStyle: null,
                           pricePerMeter: null,
                           tags: []
                       }, kitchenDataTmp, resp.data);
                       this.selectedVendors = Object.fromEntries( fields
                           .map(f => {
                               let materialField = resp.data[f];
                               if (materialField != null && materialField.vendor != null) {
                                   return [f,materialField.vendor];
                               }
                               else {
                                   return [f, null];
                               }
                           }));
                   });
            }
            this.loadStyles()
            this.loadVendors();
            this.loadTagGroups();
        },
        props: {
            id: {
                required: false,
                type: Number
            },
            creating: {
                required: false,
                type: Boolean,
                default: false
            }
        },
        computed: {
            materialFilter() {
                let filterData = {};
                if (this.kitchenMaterialPickerField != null) {
                    filterData.materialType = this.$options.KITCHEN_MATERIAL_FIELDS[this.kitchenMaterialPickerField].materialType;
                }
                if (this.selectedVendor != null && this.selectedVendor.id != null) {
                    filterData.vendor = {
                        id: this.selectedVendor.id
                    };
                }
                if (this.filterMaterialName != null) {
                    filterData.name = this.filterMaterialName;
                }
                return filterData;
            },
            materialVendorOptions() {
                if (this.kitchenMaterialPickerField) {
                    let materialType = this.$options.KITCHEN_MATERIAL_FIELDS[this.kitchenMaterialPickerField].materialType;
                    return this.materialVendors.filter(mat => mat.availableMaterialTypes.some(mType => mType === materialType));
                }
                else {
                    return this.materialVendors;
                }
            },
            tagGroups() {
                return this.tagGroupsData?.map(group => {
                    return {
                        name: group.name,
                        tags: group.tagSubgroups
                                   .flatMap(tsg => tsg.tags)
                                   .sort((tag1, tag2) => {
                                       if (tag1 == null || tag1.name == null || 'Общее' === tag1.name) {
                                           return -1;
                                       }
                                       else if (tag2 == null || tag2.name == null || 'Общее' === tag2.name) {
                                           return 1;
                                       }
                                       return tag1?.name?.localeCompare(tag2?.name, ["en", "ru"]);
                                   })
                    };
                });
            },
            tagGroupsFull() {
                return this.tagGroupsData?.map(group => {
                    return {
                        id: group.id,
                        name: group.name,
                        tagSubgroups: group.tagSubgroups
                                           .sort((tag1, tag2) => tag1?.name?.localeCompare(tag2?.name, ["en", "ru"]))
                                           .map(tsg => {
                                               return {
                                                   id: tsg.id,
                                                   name: tsg.name,
                                                   tagGroupName: group.name
                                               };
                                           })
                    };
                });
            },
            ...mapState({
                'tagGroupsData': 'tagGroups',
                kitchenStyles: 'styles',
                materialVendors: 'vendors',
                contentLoaded : state => state.stylesLoading.loaded && state.tagGroupsLoading.loaded,
            })
        },
        data(){
            return {
                imageRemoteURL: api.imagesPath,
                stubImageRemoteURL: api.stubImagePath,
                currentPage: 1,
                perPage: 10,
                totalElements: 0,
                kitchenData: null,
                tagAdditionVisible: false,
                tagAdditionData: {
                    tagSubgroup: null,
                    name: ''
                },
                submitting: false,
                tagSubmitting: false,
                selectedVendors: {},
                selectedVendor: {},
                filterMaterialName: null,
                kitchenMaterialPickerField: null,
            };
        },
        validations() {
            let kitchenDataValidator = {
                kitchenStyle: {
                    required
                },
                kitchenSubStyle: {
                    required
                },
                pricePerMeter: {
                    integer,
                    minValue: minValue(1)
                }
            };
            Object.keys(kitchenMaterialFields).forEach(key => {
                if (kitchenMaterialFields[key].required) {
                    kitchenDataValidator[key] = {
                        required
                    };
                }
                else {
                    kitchenDataValidator[key] = {};
                }
            });
            return {
                kitchenData : kitchenDataValidator,
                tagAdditionData: {
                    tagSubgroup: {
                        required
                    },
                    name: {
                        required,
                        minLength: minLength(1)
                    }
                }
            };
        },
        watch: {
            'kitchenData.kitchenStyle': function(newVal){
                if (newVal != null && this.kitchenData.kitchenSubStyle != null) {
                    if (!newVal.subStyles.some(s => s.id == this.kitchenData.kitchenSubStyle.id)) {
                        this.$v.kitchenData.kitchenSubStyle.$model = null;
                    }
                }
                else {
                    this.kitchenData.kitchenSubStyle = null;
                }
            },
            selectedVendors: function(newVal, oldVal) {
                for (let materialField in newVal) {
                    if (kitchenMaterialFields[materialField].canModifyVendor && newVal[materialField] != oldVal[materialField] && kitchenMaterialFields[materialField].vendorAffects != null) {
                        for (let depVendor of kitchenMaterialFields[materialField].vendorAffects) {
                            newVal[depVendor] = newVal[materialField];
                        }
                    }
                }
                for (let materialField in newVal) {
                    //добавить отслеживание
                    this.$watch(`selectedVendors.${materialField}`, function(newVendor) {
                        if (kitchenMaterialFields[materialField].canModifyVendor && kitchenMaterialFields[materialField].vendorAffects != null) {
                            for (let depVendor of kitchenMaterialFields[materialField].vendorAffects) {
                                this.selectedVendors[depVendor] = newVendor;
                            }
                        }
                        let selectedMaterial = this.kitchenData[materialField];
                        let newId = newVendor == null ? null : newVendor.id;
                        if (selectedMaterial == null || (selectedMaterial.vendor == null || selectedMaterial.vendor.id != newId)) {
                            this.$v.kitchenData[materialField].$model = null;
                        }
                    });
                }
                this.selectedVendors = newVal;
            },
            selectedVendor: function() {
                if (this.$refs.materialPicker != null) {
                    this.$refs.materialPicker.refresh();
                }
            }
        },
        methods: {
            onSubmit() {
                this.$v.kitchenData.$touch();
                if (this.$v.kitchenData.$anyError) {
                    this.$nextTick(() => {
                        let element = this.$el.querySelector('.multiselect.invalid, .is-invalid, .card.invalid');
                        let domRect = element.getBoundingClientRect();
                        document.body.scrollTo(
                            domRect.left + document.documentElement.scrollLeft,
                            domRect.top + document.documentElement.scrollTop
                        );
                    });
                    this.$bvToast.toast('Одно или несколько полей не были заполнены', {
                        title: 'Ошибка',
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                    return;//TODO: добавить вывод данных
                }
                this.submitting = true;
                api.saveKitchen(this.kitchenData)
                   .then(() => {
                       this.$root.$bvToast.toast('Кухня была успешно сохранена', {
                           title: 'Сохранение',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                       if (window.history != null && window.history.length > 1 && (!this.creating)) {
                           this.$router.go(-1);
                       }
                       else {
                           this.$router.push('/');
                       }
                   })
                   .catch(() => {
                       this.$bvToast.toast('Произошла ошибка при сохранении кухни', {
                           title: 'Ошибка',
                           autoHideDelay: 5000,
                           appendToast: true,
                       })
                   })
                   .finally(() => {
                       this.submitting = false;
                   });
            },
            changeMaterial(materialField) {
                this.kitchenMaterialPickerField = materialField;
                this.currentPage = 1;// назад на первую страницу
                this.selectedVendor = this.selectedVendors[this.kitchenMaterialPickerField];
                this.filterMaterialName = null;
                if (this.$options.KITCHEN_MATERIAL_FIELDS[this.kitchenMaterialPickerField].canModifyVendor) {
                    this.loadVendors();// перезагрузка вендоров
                }
                this.$refs['materialPickerModal'].show();
            },
            dropMaterial(materialField) {
                let isDecor = (Array.isArray(this.$options.KITCHEN_MATERIAL_FIELDS[materialField].vendorAffects) && this.$options.KITCHEN_MATERIAL_FIELDS[materialField].vendorAffects.length > 0);
                if (isDecor) {
                    this.$bvModal.msgBoxConfirm('Внимание! При сбросе материала декора фасада будут также удалены его компоненты: Фрезеровка, Поверхность, Патина.', {
                        okVariant: 'danger',
                        okTitle: 'Подтвердить',
                        cancelTitle: 'Отмена',
                        title: 'Подтвердите действие'
                    })
                        .then((val) => {
                            if (val) {
                                this.$v.kitchenData[materialField].$model = null;
                                this.$options.KITCHEN_MATERIAL_FIELDS[materialField].vendorAffects.forEach((field) => {
                                    this.$v.kitchenData[field].$model = null;
                                });
                            }
                        });
                }
                else {
                    this.$v.kitchenData[materialField].$model = null;
                }
            },
            addTagValue() {
                this.$v.tagAdditionData.$touch();
                if (this.$v.tagAdditionData.$anyError) {
                    return;
                }
                this.tagSubmitting = true;
                this.addNewTag({
                    tagSubgroupId: this.tagAdditionData.tagSubgroup.id,
                    name: this.tagAdditionData.name.trim()
                })
                    .then(() => {
                        this.$v.tagAdditionData.$model = {
                            tagSubgroup: null,
                            name: ''
                        };
                        this.$v.tagAdditionData.$reset();
                        this.tagAdditionVisible = false;
                    })
                    .finally(() => {
                        this.tagSubmitting = false;
                    });
            },
            onCardPicked(e) {
                let vendorChanged = (this.kitchenData[this.kitchenMaterialPickerField]?.vendor?.id != null && this.kitchenData[this.kitchenMaterialPickerField]?.vendor?.id !== e?.vendor?.id);
                let action = () => {
                    if (this.kitchenMaterialPickerField != null){
                        this.$v.kitchenData[this.kitchenMaterialPickerField].$model = e;
                        this.selectedVendors[this.kitchenMaterialPickerField] = e.vendor;
                    }
                    this.$refs['materialPickerModal'].hide();
                };
                if (vendorChanged && Array.isArray(this.$options.KITCHEN_MATERIAL_FIELDS[this.kitchenMaterialPickerField].vendorAffects) && this.$options.KITCHEN_MATERIAL_FIELDS[this.kitchenMaterialPickerField].vendorAffects.length > 0) {
                    this.$bvModal.msgBoxConfirm('Внимание! При изменении поставщика декора фасада будут удалены его компоненты: Фрезеровка, Поверхность, Патина.', {
                        okVariant: 'danger',
                        okTitle: 'Подтвердить',
                        cancelTitle: 'Отмена',
                        title: 'Подтвердите действие'
                    })
                        .then((val) => {
                            if (val) {
                                action();
                            }
                        });
                }
                else {
                    action();
                }
            },
            loadMaterials(context){
                if (this.materialFilter.vendor == null) {
                    return [];
                }
                return api.getMaterialData(context.perPage, context.currentPage - 1, 'name', 'asc', this.materialFilter)
                          .then(resp => {
                              this.totalElements = resp.data.totalElements;
                              return resp.data.content;
                          });
            },
            materialStatusVariant(status) {
                return AVAILABILITY_STATUS[status]?.variant;
            },
            materialStatusText(status) {
                return AVAILABILITY_STATUS[status]?.text;
            },
            ...mapActions(['loadTagGroups', 'loadStyles', 'loadVendors', 'addNewTag'])
        }
    }
</script>
<style scoped>
    .multiselect.invalid >>> .multiselect__tags,
    .card.invalid {
        border-color: #f04124 !important;
    }
    .available-checkbox {
        margin-top: 2.35rem;
    }
</style>
