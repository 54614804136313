<template>
    <b-container>
        <tag-list class="mb-4"/>
    </b-container>
</template>
<script>
 import TagList from '@/components/TagList';
 export default {
     name: 'TagEditPage',
     components: {
         'tag-list': TagList
     }
 }
</script>
