<template>
    <b-row>
        <b-col>
            <material-card
                title="Старый материал"
                :state="oldMaterialState"
                :material="oldMaterial"
                @replace-material="(ev) => $emit('replace-old-material', ev)"
                @drop-material="(ev) => $emit('drop-old-material', ev)"
            >
            </material-card>
        </b-col>
        <b-col>
            <material-card
                title="Новый материал"
                :state="newMaterialState"
                :material="newMaterial"
                @replace-material="(ev) => $emit('replace-new-material', ev)"
                @drop-material="(ev) => $emit('drop-new-material', ev)"
            >
            </material-card>
        </b-col>
    </b-row>
</template>
<script>
    import MaterialCard from './MaterialCard';
    export default {
        components: {
            'material-card': MaterialCard
        },
        props: {
            oldMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            newMaterial: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            oldMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            newMaterialState: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            }
        },
    }
</script>
