<template>
    <b-card :title="title" :class="{ 'flex-fill': true, 'invalid': state === false }">
        <div class="text-center">
            <div><span>{{material != null ? material.name : 'Не указан'}}</span></div>
            <div class="position-relative mt-2" v-if="material != null">
                <b-badge :variant="statusVariant" class="badge-overlay">{{statusText}}</b-badge>
                <img class="mw-100" :src="Array.isArray(material.previewPath) && material.previewPath.length > 0 ? `${imageRemoteURL}/${material.previewPath[0]}` : stubImagePath"/>
            </div>
            <b-row class="mt-2">
                <b-col>
                    <b-link @click="(ev) => $emit('replace-material', ev)" class="ml-1" >Изменить <b-icon icon="pencil"/></b-link>
                    <b-link @click="(ev) => $emit('drop-material', ev)" class="ml-1">Очистить <b-icon icon="x"/></b-link>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>
<script>
    import api from '@/api/backend-api';
    const AVAILABILITY_STATUS = {
        UNAVAILABLE: {
            variant: 'danger',
            text: 'Снят'
        },
        STOP_SALES: {
            variant: 'warning',
            text: 'В СТОПе'
        },
        AVAILABLE: {
            variant: 'success',
            text: 'Доступно'
        },
    }
    export default {
        props: {
            material: {
                required: true,
                validator: (prop) => prop === null || typeof prop === 'object'
            },
            state: {
                required: false,
                default: null,
                validator: (prop) => prop === null || typeof prop === 'boolean'
            },
            title: {
                required: false,
                default: 'Материал',
                type: String
            },
            stubImageURL: {
                required: false,
                type: String
            }
        },
        computed: {
            statusVariant() {
                return AVAILABILITY_STATUS[this.material?.availability]?.variant;
            },
            statusText() {
                return AVAILABILITY_STATUS[this.material?.availability]?.text;
            },
            stubImagePath() {
                return this.stubImageURL ? this.stubImageURL : api.stubImagePath;
            }
        },
        data() {
            return {
                imageRemoteURL: api.imagesPath
            };
        }
    }
</script>
